import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-popover-container',
  templateUrl: './popover-container.component.html',
  standalone: true,
  imports: [MatCardModule],
})
export class PopoverContainerComponent {}
