import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { inject, Injectable, signal, TemplateRef, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OverlaySpinnerService {
  readonly loading = signal(false);
  private readonly overlay = inject(Overlay);

  showLoader(): void {
    this.loading.set(true);
  }

  hideLoader(): void {
    this.loading.set(false);
  }

  createOverlay(config?: Omit<OverlayConfig, 'positionStrategy' | 'hasBackdrop'>): OverlayRef {
    return this.overlay.create({
      ...config,
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });
  }

  attachTemplatePortal(overlayRef: OverlayRef, templateRef: TemplateRef<unknown>, vcRef: ViewContainerRef): void {
    overlayRef.attach(new TemplatePortal(templateRef, vcRef));
  }
}
