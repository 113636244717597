import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OneOrmDatePipe } from '@shared/pipe/date-format.pipe';

import { IsValidDatePipe } from '@shared/pipe';

import { OverdueCellColorDirective } from './overdue-cell-color.directive';
import { CellRenderer, CellRendererParams } from '../../model/cell-renderer.model';
import { TableColumnDef } from '../../model/col-def.model';

@Component({
  template: `
    <span *ngIf="fieldValue | isValidDate; else numericalValue" [type]="'date'" [value]="fieldValue" appOverdueCellColor>
      {{ fieldValue | oneOrmDate }}
    </span>
    <ng-template #numericalValue>
      <span [type]="'number'" [value]="fieldValue" appOverdueCellColor>{{ fieldValue }}</span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OneOrmDatePipe, OverdueCellColorDirective, NgIf, IsValidDatePipe],
  standalone: true,
})
export class OverdueCellRendererComponent<T> implements CellRenderer<T> {
  data: T;
  column: TableColumnDef<T>;
  fieldValue: unknown;

  cellRendererInit(params: CellRendererParams<T>): void {
    this.fieldValue = params.data[params.column.field];
    this.data = params.data;
    this.column = params.column;
  }
}
