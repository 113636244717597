import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ToastService {
  private readonly actionText = 'Dismiss';

  constructor(private readonly snackbar: MatSnackBar) {}

  showSuccessToast(message: string): void {
    this.snackbar.open(message, this.actionText);
  }

  showErrorToast(message: string): void {
    this.snackbar.open(message, this.actionText, {
      panelClass: ['error-snackbar'],
    });
  }
}
