import { Injectable } from '@angular/core';
import { UserService as OneormUsersService } from 'oneorm-api-http-client';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isEmpty } from '@shared/utils';

import { FilterDropdownOption } from '../models/filter-dropdown-option.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private readonly usersService: OneormUsersService) {}

  getUsers(filter: string): Observable<FilterDropdownOption[]> {
    if (isEmpty(filter)) {
      return of([]);
    }

    return this.usersService.getUsersList(filter, true).pipe(
      map(({ data }) => data ?? []),
      map(users =>
        users.map(
          user =>
            ({
              label: user?.user_name,
              id: user?.user_id,
            }) as FilterDropdownOption
        )
      )
    );
  }
}
