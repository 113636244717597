import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DrawerService } from '@core/drawer/drawer.service';
import { RightSidenavStore } from '@core/right-sidenav/right-sidenav.store';
import { LetDirective } from '@ngrx/component';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { UserStore } from '@core/authorization';
import { SkeletonLoaderDirective } from '@shared/directives';
import { IsFalsePipe } from '@shared/pipe';
import { isNotEmpty } from '@shared/utils';

import { supportMassage } from './right-bar-constant';

export enum Status {
  Available = 'Available',
  AvailableIdle = 'AvailableIdle',
  Away = 'Away',
  BeRightBack = 'BeRightBack',
  Busy = 'Busy',
  BusyIdle = 'BusyIdle',
  DoNotDisturb = 'DoNotDisturb',
  Offline = 'Offline',
  PresenceUnknown = 'PresenceUnknown',
}

@Component({
  selector: 'app-right-sidenav',
  templateUrl: './right-sidenav.component.html',
  styleUrls: ['./right-sidenav.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    NgIf,
    SkeletonLoaderDirective,
    NgFor,
    NgStyle,
    MatDividerModule,
    AsyncPipe,
    LetDirective,
    IsFalsePipe,
  ],
})
export class RightSidenavComponent {
  numberOfItems = 3;
  numberOfItemsL = 3;

  readonly governanceCoordinators$ = this.store.governanceCoordinators$.pipe(filter(isNotEmpty));
  readonly complianceContacts$ = this.store.complianceContacts$.pipe(filter(isNotEmpty));
  readonly riskManagers$ = this.store.riskManagers$.pipe(filter(isNotEmpty));
  readonly noContactsAvailable$ = combineLatest([
    this.store.governanceCoordinators$,
    this.store.complianceContacts$,
    this.store.riskManagers$,
    this.store.loading$,
  ]).pipe(
    map(
      ([governanceCoordinators, complianceContacts, riskManagers, loading]) =>
        governanceCoordinators.length === 0 && complianceContacts.length === 0 && riskManagers.length === 0 && !loading
    ),
    distinctUntilChanged()
  );
  readonly loadingContacts$ = this.store.loading$;

  private readonly userStore = inject(UserStore);

  constructor(
    private readonly drawerService: DrawerService,
    private readonly store: RightSidenavStore
  ) {}

  sendChatMessage(messageTo: string): void {
    let link = `${supportMassage.linkUser}${this.userStore.user_email_address()},${messageTo}`;
    link = `${link}${supportMassage.opEventSupportMessage}`;
    location.href = link;
  }

  scheduleMeeting(meetTo: string): void {
    let link = `${supportMassage.linkAttendees}${meetTo}`;
    link = `${link}${supportMassage.opEventSupport}`;
    location.href = link;
  }

  sendEmail(to: string, name: string): void {
    let subject = '';
    let body = 'Hello ' + name + ',%0D%0A%0D%0A I need your support on risk case reporting process... ';
    subject = 'Support request on a generic risk case';
    body = body + '%0D%0A%0D%0A%0D%0A Kind Regards,%0D%0A%0D%0A' + this.userStore.user_name();
    window.location.href = 'mailto:' + to + '?subject=' + subject + '&body=' + body;
  }

  getColor(status: Status): {
    position: string;
    bottom: string;
    right: string;
    'font-size': string;
    color: string;
    border: string;
    'border-radius': string;
    'background-color': string;
  } {
    let color;
    switch (status) {
      case Status.Available:
        color = '#32a852';
        break;
      case Status.Away:
        color = '#fcba03';
        break;
      case Status.BeRightBack:
        color = '#fcba03';
        break;
      case Status.Busy:
        color = '#eb4034';
        break;
      case Status.DoNotDisturb:
        color = '#eb4034';
        break;
      case Status.Offline:
        color = 'grey';
        break;
      default:
        color = 'grey';
        break;
    }
    return {
      position: 'absolute',
      bottom: '0px',
      right: '5px',
      'font-size': '17px',
      color,
      border: '2px solid #ffffff',
      'border-radius': '50%',
      'background-color': '#ffffff',
    };
  }

  getPresenceIcon(status: Status): 'check_circle' | 'watch_later' | 'lens' | 'remove_circle' | 'cancel' {
    switch (status) {
      case Status.Available:
        return 'check_circle';
      case Status.Away:
        return 'watch_later';
      case Status.BeRightBack:
        return 'watch_later';
      case Status.Busy:
        return 'lens';
      case Status.DoNotDisturb:
        return 'remove_circle';
      case Status.Offline:
        break;
      default: //Status.PresenceUnknown
        return 'cancel';
    }

    return 'cancel';
  }

  closeBar(): void {
    this.drawerService.setOpen(false);
  }
}
