<mat-toolbar class="toolbar p-0">
  <div class="flex-centerX-centerY w-100 mx-3">
    <div class="logo-container flex-centerY">
      <img class="small-screen" (click)="onClick()" alt="SwissRe logo" src="./assets/images/SwissRe_Logo_Lake.svg" />
      <img class="small-screen" (click)="onClick()" alt="OneORM logo" src="./assets/images/OneORM_LOGO.svg" />
      <div class="appName flex-centerY">
        @if (environmentType | isNotEmpty) {
          <span>({{ environmentType }})</span>
          <app-commit-version></app-commit-version>
        }
      </div>
      <app-breadcrumb></app-breadcrumb>
    </div>
    <div class="flex-centerX-centerY">
      <app-manage-menu></app-manage-menu>
      <button class="flex-centerY mx-3" *ngIf="drawerOpened$ | async | isFalse" (click)="openDrawer()" color="primary" mat-flat-button>
        <span class="material-icons"> chat </span> Ask Someone
      </button>
      <button class="flex-centerY mx-3" *ngIf="drawerOpened$ | async" (click)="closeDrawer()" color="primary" mat-stroked-button>
        <span class="material-icons"> chat </span> Ask Someone
      </button>
      <img class="profileImg" [src]="userPhoto()" alt="Profile Picture" onError="this.src='assets/images/app/profile.PNG'" />
    </div>
  </div>
</mat-toolbar>
