import { Injectable } from '@angular/core';
import { EventsStatusEnumAdapter } from '@shared/adapters/events-status-enum.adapter';
import { EventsTypeEnumAdapter } from '@shared/adapters/events-type-enum.adapter';
import { RiskCaseEnum } from '@shared/constants/constants';
import { HomeEnumService } from '@shared/services/home-enum.service';
import { EnumService } from 'oneorm-api-http-client';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EventsCheckboxFilter } from '../../home/model/events-filter.model';
import { FilterCheckboxGroupModel } from '../../rcsa/filter/filter-checkbox-group.model';

@Injectable()
export class EventsEnumService extends HomeEnumService<EventsCheckboxFilter> {
  constructor(private readonly enumService: EnumService) {
    super();
  }

  override getCheckboxFilters(): Observable<Record<EventsCheckboxFilter, FilterCheckboxGroupModel>> {
    return forkJoin([
      this.enumService.getEnumItems(RiskCaseEnum.EVENT_TYPE).pipe(
        map(data => data?.data),
        map(data => EventsTypeEnumAdapter.fromDto(data))
      ),
      this.enumService.getEnumItems(RiskCaseEnum.EVENT_STATUS).pipe(
        map(data => data?.data),
        map(data => EventsStatusEnumAdapter.fromDto(data))
      ),
    ]).pipe(
      map(([{ type }, { status }]) => ({
        type,
        status,
      }))
    );
  }
}
