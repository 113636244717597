import { ChangeDetectorRef, Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { SkeletonLoaderComponent } from '@shared/components/skeleton-loader.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[skeleton]', standalone: true })
export class SkeletonLoaderDirective implements OnChanges {
  @Input('skeleton') isLoading = false;
  @Input('skeletonRepeat') repeat = 1;
  @Input('skeletonWidth') width: string;
  @Input('skeletonHeight') height: string;
  @Input('skeletonBorderRadius') borderRadius: string;
  @Input('skeletonMargin') margin: string;
  @Input('skeletonClassName') className: string;

  constructor(
    private readonly tpl: TemplateRef<unknown>,
    private readonly vcr: ViewContainerRef,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isLoading']) {
      this.vcr.clear();
      this.cdr.markForCheck();

      if (changes['isLoading'].currentValue) {
        Array.from({ length: this.repeat }).forEach(() => {
          const ref = this.vcr.createComponent(SkeletonLoaderComponent);

          Object.assign(ref.instance, {
            width: this.width,
            height: this.height,
            borderRadius: this.borderRadius,
            margin: this.margin,
            className: this.className,
          });
        });
      } else {
        this.vcr.createEmbeddedView(this.tpl);
        this.cdr.markForCheck();
      }
    }
  }
}
