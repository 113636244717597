import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CellRenderer, CellRendererParams } from '@styles/p-table/model/cell-renderer.model';
import { TableColumnDef } from '@styles/p-table/model/col-def.model';

import { OneOrmDatePipe, IsNotNilPipe } from '@shared/pipe';

@Component({
  template: `<ng-container *ngIf="data | isNotNil">{{ data[column.field] | oneOrmDate }}</ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OneOrmDatePipe, NgIf, IsNotNilPipe],
})
export class DateCellRendererComponent<T> implements CellRenderer<T> {
  data: T;
  column: TableColumnDef<T>;

  cellRendererInit(params: CellRendererParams<T>): void {
    this.data = params.data;
    this.column = params.column;
  }
}
