import { AfterContentInit, Directive, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Directive({ selector: '[appShowMore]', standalone: true })
export class ShowMoreDirective implements AfterContentInit, OnChanges {
  @Input() noOfLines: number = 3;
  @Input() showMoreTogget: boolean = false;
  @Output() showMoreView: EventEmitter<boolean> = new EventEmitter<boolean>();
  actualHeight: number;
  visibleHeight: number;

  constructor(private readonly elementView: ElementRef) {}

  ngAfterContentInit(): void {
    //calculate line height
    let temp: HTMLElement = document.createElement(this.elementView.nativeElement.nodeName) as HTMLElement;
    let calculatedLineHeight: number;
    temp.setAttribute(
      'style',
      'margin:0; padding:0; ' +
        'font-family:' +
        (this.elementView.nativeElement.style.fontFamily || 'inherit') +
        '; ' +
        'font-size:' +
        (this.elementView.nativeElement.style.fontSize || 'inherit') +
        '; ' +
        'line-height:' +
        (this.elementView.nativeElement.style.lineHeight || 'inherit')
    );
    temp.innerHTML = 'A';
    this.elementView.nativeElement.appendChild(temp);
    calculatedLineHeight = temp.clientHeight;
    temp.parentNode.removeChild(temp);
    this.elementView.nativeElement.style.maxHeight = calculatedLineHeight * this.noOfLines + 'px';
    this.actualHeight = (this.elementView.nativeElement as HTMLElement).scrollHeight;
    this.visibleHeight = calculatedLineHeight * this.noOfLines;
    if (this.visibleHeight < this.actualHeight) {
      this.showMoreView.emit(true);
      this.elementView.nativeElement.classList.add('show-more-text');
      this.elementView.nativeElement.classList.add('mb-1');
    } else {
      this.showMoreView.emit(false);
    }
  }

  ngOnChanges(): void {
    if (this.showMoreTogget) {
      this.elementView.nativeElement.classList.add('expand');
    } else {
      this.elementView.nativeElement.classList.toggle('expand');
    }
  }
}
