import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';

import { FilterDropdownOption } from '@shared/models';
import { isEmpty, isNil, isNotNil } from '@shared/utils';

@Component({
  selector: 'app-autocomplete',
  template: `
    <mat-form-field class="full-width">
      <mat-label>{{ label }}</mat-label>
      <input [formControl]="inputFormControl" [matAutocomplete]="auto" data-test="autocomplete-input" matInput type="text" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelected.emit($event.option.value)">
        <mat-option>None</mat-option>
        @defer (on viewport) {
          @for (option of filteredOptions$ | async; track option.id) {
            <mat-option [value]="option">{{ option.label }}</mat-option>
          }
        } @placeholder {
          <mat-option>Loading...</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  `,
  standalone: true,
  imports: [MatFormField, MatLabel, MatAutocomplete, MatOption, ReactiveFormsModule, MatAutocompleteTrigger, MatInput, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent<T> {
  @Input() label: string;
  @Input({
    required: true,
  })
  inputFormControl: FormControl<FilterDropdownOption<T>>;

  @Output() optionSelected = new EventEmitter<FilterDropdownOption<T>>();

  availableOptions: FilterDropdownOption<T>[] = [];
  filteredOptions$: Observable<FilterDropdownOption<T>[]>;

  @Input() set options(options: FilterDropdownOption<T>[]) {
    this.availableOptions = options;
    this.filteredOptions$ = this.getFilteredOptions();
  }

  displayFn(option: FilterDropdownOption<T>): string {
    return isNotNil(option) ? option.label : '';
  }

  private filterOptions(value: FilterDropdownOption<T> | string): FilterDropdownOption<T>[] {
    if (isNil(value) || isEmpty(value)) {
      return this.availableOptions;
    }

    if (typeof value === 'string') {
      return this.availableOptions.filter(option => option.label.toLowerCase().includes(value.toLowerCase()));
    }

    const label = value.label.toLowerCase();
    return this.availableOptions.filter(option => option.label.toLowerCase().includes(label));
  }

  private getFilteredOptions(): Observable<FilterDropdownOption<T>[]> {
    return this.inputFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value))
    );
  }
}
