import { Injectable } from '@angular/core';
import { RcsaService } from 'oneorm-api-http-client';
import { RiskAssessment } from 'oneorm-api-http-client/model/riskAssessment';
import { map, Observable } from 'rxjs';

import { HomeService } from '@shared/services';
import { isNotNil } from '@shared/utils';

import { TableData } from '../../home/model/table-data.model';

@Injectable()
export class RcsaFeService extends HomeService<RiskAssessment[]> {
  constructor(private readonly rcsaService: RcsaService) {
    super();
  }

  getData(): Observable<TableData<RiskAssessment[]>> {
    return this.rcsaService.getRCSAs().pipe(map(({ data }) => ({ data })));
  }

  getGlobalFunctions(): Observable<string[]> {
    return this.rcsaService.getAllGlobalFunctions().pipe(
      map(({ data }) => data),
      map(data => data.filter(obj => isNotNil(obj)))
    );
  }
}
