import { Directive, ElementRef } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'tr[pTableRow]', standalone: true })
export class PTableRowDirective {
  readonly nativeElement: HTMLTableRowElement;
  constructor(private readonly elementRef: ElementRef<HTMLTableRowElement>) {
    this.nativeElement = elementRef.nativeElement;
  }
}
