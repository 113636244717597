import { Pipe, PipeTransform } from '@angular/core';
import { isFalse } from '@shared/utils/boolean/is-false';

@Pipe({
  standalone: true,
  name: 'isFalse',
})
export class IsFalsePipe implements PipeTransform {
  transform(value: boolean): boolean {
    return isFalse(value);
  }
}
