export enum StatusCheckboxFilter {
  draft = 'draft',
  open = 'open',
  completed = 'completed',
  cancelled = 'cancelled',
  closed = 'closed',
}

export enum StatusCheckboxFilterValue {
  draft = 'Draft',
  open = 'Open',
  completed = 'Completed',
  cancelled = 'Cancelled',
  closed = 'Closed',
}
