export enum IssuesAutocompleteFilter {
  creator = 'creator',
  owner = 'owner',
  delegate = 'delegate',
  rcsa_owner = 'rcsa_owner',
  risk_manager = 'risk_manager',
  governanceCoordinator = 'governanceCoordinator',
  bu_gf = 'bu_gf',
  actionOwner = 'actionOwner',
  actionDelegate = 'actionDelegate',
}

export enum IssuesCheckboxFilter {
  identifyingLod = 'identifyingLod',
  impactRating = 'impactRating',
  status = 'status',
}

export enum IssuesIdentifyingLodCheckboxFilter {
  self = 'self',
  secondLine = 'secondLine',
  thirdLine = 'thirdLine',
}

export enum IssuesIdentifyingLodCheckboxFilterValue {
  self = '1\u02E2\u1D57 (self-identified)',
  secondLine = '2\u207F\u1D48 (not self-identified)',
  thirdLineLine = '3\u02B3\u1D48 (not self-identified)',
}

export enum IssuesImpactRatingCheckboxFilter {
  low = 'low',
  moderate = 'moderate',
  elevated = 'elevated',
  high = 'high',
}

export enum IssuesImpactRatingCheckboxFilterValue {
  low = 'Low',
  moderate = 'Moderate',
  elevated = 'Elevated',
  high = 'High',
}
