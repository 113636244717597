import { Pipe, PipeTransform } from '@angular/core';

import { isNil } from '@shared/utils';

@Pipe({
  name: 'toString',
  standalone: true,
})
export class ToStringPipe implements PipeTransform {
  transform(value: number): string {
    if (isNil(value) || isNaN(value)) {
      return '';
    }
    return value.toString();
  }
}
