import { User } from 'oneorm-api-http-client';

import { isNil, isObject } from '@shared/utils';

export const isUser = (row: unknown): row is User => {
  if (isNil(row) || !isObject(row)) {
    return false;
  }

  return row.hasOwnProperty('user_id') && row.hasOwnProperty('user_name');
};
