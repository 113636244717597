import { Renderer2, Directive, ElementRef, Input, OnInit } from '@angular/core';

import { ColumnWidth } from '@shared/models';

@Directive({ selector: '[appColumnWidth]', standalone: true })
export class ColumnWidthDirective implements OnInit {
  @Input() columnWidth: ColumnWidth;
  private readonly domElement: HTMLElement;

  constructor(
    private readonly renderer: Renderer2,
    elementRef: ElementRef
  ) {
    this.domElement = elementRef.nativeElement as HTMLElement;
  }

  ngOnInit(): void {
    if (this.columnWidth && this.columnWidth.minWidth) {
      this.renderer.setStyle(this.domElement, 'min-width', `${this.columnWidth.minWidth}px`);
    }
    if (this.columnWidth && this.columnWidth.maxWidth) {
      this.renderer.setStyle(this.domElement, 'max-width', `${this.columnWidth.maxWidth}px`);
    }
    if (this.columnWidth && this.columnWidth.width) {
      this.renderer.setStyle(this.domElement, 'width', `${this.columnWidth.width}px`);
      this.renderer.setStyle(this.domElement, 'max-width', `${this.columnWidth.width}px`);
      this.renderer.setStyle(this.domElement, 'min-width', `${this.columnWidth.width}px`);
    }
  }
}
