import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PopoverService {
  readonly state$: Observable<boolean>;
  private readonly state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.state$ = this.state.asObservable();
  }

  closePopover(): void {
    return this.state.next(true);
  }
}
