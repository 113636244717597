import { Adapter } from '@shared/adapters/adapter';
import { RiskCaseEnumItem } from 'oneorm-api-http-client/model/riskCaseEnumItem';

import { Option } from '@shared/models';
import { isNil, isNotNil } from '@shared/utils';

export abstract class EnumOptionAdapter extends Adapter<Option[], RiskCaseEnumItem[]> {
  static fromDto(dto: RiskCaseEnumItem[]): Option[] {
    if (isNil(dto) || isNil(dto[0])) {
      return [];
    }
    return dto.map(({ id, name, description }) => {
      const hasDescription = isNotNil(description) && description.trim() !== '';
      const label = hasDescription
        ? `${name} ${EnumOptionAdapter.getLeadingBracket(description)}${description}${EnumOptionAdapter.getTrailingBracket(description)}`
        : name;

      return {
        id,
        label: label.trim(),
      };
    });
  }

  static toDto(option: Option): RiskCaseEnumItem {
    if (isNil(option)) {
      return undefined;
    }

    return {
      id: option.id,
      name: option.label,
    };
  }

  private static getLeadingBracket(description: string): string {
    return description.startsWith('(') ? '' : '(';
  }

  private static getTrailingBracket(description: string): string {
    return description.endsWith(')') ? '' : ')';
  }
}
