import { booleanAttribute, Directive, EventEmitter, HostBinding, HostListener, Input, numberAttribute, Output } from '@angular/core';

@Directive({
  selector: '[appSelectable]',
  standalone: true,
})
export class SelectableDirective {
  @Input({
    transform: numberAttribute,
  })
  id: number;
  @Input({
    transform: booleanAttribute,
  })
  selected: boolean;
  @Output() selectChange = new EventEmitter<number>();

  @HostBinding('class.selectable')
  private readonly selectableClassApplied = true;

  @HostBinding('class.selected')
  private get selectedClassApplied(): boolean {
    return this.selected;
  }

  @HostListener('click', ['$event'])
  private onClick(): void {
    this.selectChange.emit(this.id);
  }
}
