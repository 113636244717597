const TABS_HEIGHT = 48;
const PAGE_HEADER_LABEL_HEIGHT = 76;
const TABLE_FILTER_HEIGHT = 76;
const ROWS_COUNTER_HEIGHT = 20;
const BORDER = -5;

type ComponentPage = 'risks' | 'control-assessments' | 'control-details' | 'controls-gcc' | 'controls-in-other-dept' | 'events' | 'issues';

const pageOffset: Record<ComponentPage, number> = {
  risks: TABS_HEIGHT + PAGE_HEADER_LABEL_HEIGHT + TABLE_FILTER_HEIGHT + ROWS_COUNTER_HEIGHT,
  ['control-assessments']: TABS_HEIGHT + PAGE_HEADER_LABEL_HEIGHT + TABLE_FILTER_HEIGHT + ROWS_COUNTER_HEIGHT,
  ['control-details']: TABS_HEIGHT + PAGE_HEADER_LABEL_HEIGHT + ROWS_COUNTER_HEIGHT,
  ['controls-gcc']: TABS_HEIGHT + PAGE_HEADER_LABEL_HEIGHT + ROWS_COUNTER_HEIGHT,
  ['controls-in-other-dept']: TABS_HEIGHT + PAGE_HEADER_LABEL_HEIGHT + TABLE_FILTER_HEIGHT + ROWS_COUNTER_HEIGHT,
  events: TABS_HEIGHT + PAGE_HEADER_LABEL_HEIGHT + TABLE_FILTER_HEIGHT + ROWS_COUNTER_HEIGHT,
  issues: TABS_HEIGHT + PAGE_HEADER_LABEL_HEIGHT + TABLE_FILTER_HEIGHT + ROWS_COUNTER_HEIGHT,
};

export const getTableScrollHeight = (cardHeight: number, page: ComponentPage): string => cardHeight - (pageOffset[page] + BORDER) + 'px';
