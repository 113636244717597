import { Injectable } from '@angular/core';

import { KeyAsString } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService<T extends object> {
  saveData(key: KeyAsString<T>, value: string): void {
    sessionStorage.setItem(key, value);
  }

  getData(key: KeyAsString<T>): string {
    return sessionStorage.getItem(key);
  }

  removeData(key: KeyAsString<T>): void {
    sessionStorage.removeItem(key);
  }

  clearData(): void {
    sessionStorage.clear();
  }
}
