import { Page } from '@styles/p-table/model/page.model';
import { Size, SizeClass } from '@styles/p-table/model/table.model';

export const SIZE_P_TABLE_SIZE_CLASS: Record<Size, SizeClass> = {
  small: 'p-datatable-sm',
  medium: '',
  large: 'p-datatable-lg',
};

export const DEFAULT_PAGE_SIZE = 49;
export const DEFAULT_PAGE_OFFSET = 0;
export const DEFAULT_LIMIT_SIZE = DEFAULT_PAGE_SIZE + 1;
export const MAXIMUM_LIMIT_SIZE = 9999;

export const DEFAULT_PAGE: Page = {
  offset: DEFAULT_PAGE_OFFSET,
  size: DEFAULT_LIMIT_SIZE,
};
