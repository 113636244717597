interface StepButton {
  label: string;
  disabled?: boolean;
}

export interface OrmStep<Key extends string = string, SubKey extends string = string> {
  label: string;
  index?: number;
  stepperIndex?: number;
  initialRoute?: string;
  route: string;
  subSteps?: (Omit<OrmStep<SubKey>, 'subSteps' | 'index'> & { index: number })[];
  stepControlKey?: Key;
  buttons?: {
    primary?: StepButton;
    secondary?: StepButton;
    tertiary?: StepButton;
  };
  completed?: boolean;
}
