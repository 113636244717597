import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { BehaviorSubject, distinctUntilChanged, Observable, shareReplay, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DrawerService implements OnDestroy {
  readonly opened$: Observable<boolean>;
  readonly mode$: Observable<MatDrawerMode>;

  private readonly _opened$ = new BehaviorSubject(false);
  private readonly _mode$ = new BehaviorSubject<MatDrawerMode>('side');
  private readonly destroy$ = new Subject<void>();
  private readonly sidenavMargin = 300;
  private readonly xLargeMaterialBreakpoint = 1920;

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.opened$ = this._opened$.asObservable().pipe(shareReplay(1));
    this.mode$ = this._mode$.asObservable().pipe(shareReplay(1));
    this.breakpointObserver
      .observe([
        Breakpoints.Small,
        Breakpoints.XSmall,
        `(min-width: ${this.sidenavMargin * 2 + this.xLargeMaterialBreakpoint}px)`,
        Breakpoints.TabletLandscape,
      ])
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(({ matches }) => this._mode$.next(this.getDrawerMode(matches)));
  }

  setOpen(opened: boolean): void {
    this._opened$.next(opened);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getDrawerMode(matchBreakpoint: boolean): MatDrawerMode {
    return matchBreakpoint ? 'over' : 'side';
  }
}
