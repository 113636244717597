import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ComplianceService, GovernanceCoordinator, GovernanceCoordinatorsService, RCSA, UserService } from 'oneorm-api-http-client';
import { distinctUntilChanged, forkJoin, Observable, shareReplay, switchMap } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { GraphUser } from '@shared/models';
import { GraphService } from '@shared/services';
import { isEmpty, isEqual, isNotEmpty, isNotNil, uniqBy } from '@shared/utils';

interface RightSidenavState {
  governanceCoordinators: GraphUser[];
  riskManagers: GraphUser[];
  complianceContacts: GraphUser[];
  rcsas: RCSA[];
  loading: boolean;
}

@Injectable()
export class RightSidenavStore extends ComponentStore<RightSidenavState> {
  readonly governanceCoordinators$ = this.select(state => state.governanceCoordinators);
  readonly complianceContacts$ = this.select(state => state.complianceContacts);
  readonly riskManagers$ = this.select(state => state.riskManagers);
  readonly loading$ = this.select(state => state.loading).pipe(distinctUntilChanged());

  private readonly governanceContactType = 'Governance Contact';
  private readonly emptyDivision = 'empty';

  private readonly rcsas$ = this.select(state => state.rcsas).pipe(
    tap(rcsas => {
      if (isEmpty(rcsas)) {
        this.patchState({ loading: false });
      }
    }),
    filter(isNotEmpty),
    distinctUntilChanged((x, y) => isEqual(x, y)),
    tap(() => this.resetState()),
    shareReplay(1)
  );

  private readonly getGovernanceCoordinatorsOnRcsaChange = this.effect(() =>
    this.rcsas$.pipe(
      tap(rcas => {
        this.getGovernanceCoordinators(rcas);
        this.getComplianceContacts(rcas);
        this.getRiskManagers(rcas);
      })
    )
  );

  private readonly getGovernanceCoordinators = this.effect((trigger$: Observable<RCSA[]>) =>
    trigger$.pipe(
      map(rcsas => rcsas.filter(rcsa => isNotNil(rcsa.risk_assessment_id))),
      switchMap(rcsas => this.getGovernanceCoordinatorsGraphUsers(rcsas))
    )
  );

  private readonly getComplianceContacts = this.effect((trigger$: Observable<RCSA[]>) =>
    trigger$.pipe(
      map(rcsas => rcsas.filter(rcsa => isNotNil(rcsa.risk_assessment_id))),
      switchMap(rcsas => this.getComplianceContactsGraphInfo(rcsas))
    )
  );

  private readonly getRiskManagers = this.effect((trigger$: Observable<RCSA[]>) =>
    trigger$.pipe(switchMap(rcsas => this.getRiskManagersGraphInfo(rcsas)))
  );

  constructor(
    private readonly governanceCoordinatorsService: GovernanceCoordinatorsService,
    private readonly complianceService: ComplianceService,
    private readonly usersService: UserService,
    private readonly graphService: GraphService
  ) {
    super({
      governanceCoordinators: [],
      rcsas: [],
      complianceContacts: [],
      riskManagers: [],
      loading: true,
    });
  }

  private resetState(): void {
    this.patchState({
      governanceCoordinators: [],
      complianceContacts: [],
      riskManagers: [],
      loading: true,
    });
  }

  private getGovernanceCoordinatorsGraphUsers(rcsas: RCSA[]): Observable<GraphUser[]> {
    return forkJoin(rcsas.map(rcsa => this.governanceCoordinatorsService.getGovernanceCoordinators(rcsa.risk_assessment_id))).pipe(
      map(contactsResponse => contactsResponse.flatMap(contactResponse => contactResponse.data)),
      map(contacts => contacts.filter(contact => this.isGovernanceContact(contact))),
      filter(isNotEmpty),
      map(contacts => uniqBy(contacts, 'contact_id')),
      switchMap(contacts => this.graphService.getGraphUsers(contacts.map(governanceCoordinator => governanceCoordinator.contact_email))),
      tap(governanceCoordinators => this.patchState({ governanceCoordinators, loading: false }))
    );
  }

  private getComplianceContactsGraphInfo(rcsas: RCSA[]): Observable<GraphUser[]> {
    return forkJoin(
      rcsas
        .filter(rcsa => isNotNil(rcsa.global_function))
        .map(rcsa => this.complianceService.getComplianceList(rcsa.global_function, rcsa.division ?? this.emptyDivision))
    ).pipe(
      map(contactsResponse => contactsResponse.flatMap(contactResponse => contactResponse.data)),
      filter(isNotEmpty),
      map(complianceContacts => uniqBy(complianceContacts, 'compliance_contact.user_id')),
      map(complianceContacts => complianceContacts.map(complianceContact => complianceContact.compliance_contact)),
      switchMap(complianceContacts => this.graphService.getGraphUsers(complianceContacts.map(contact => contact.user_email_address))),
      tap(complianceContacts => this.patchState({ complianceContacts, loading: false }))
    );
  }

  private getRiskManagersGraphInfo(rcsas: RCSA[]): Observable<GraphUser[]> {
    return forkJoin(
      rcsas.filter(rcsa => isNotNil(rcsa.risk_manager_id)).map(rcsa => this.usersService.getUserById(rcsa.risk_manager_id))
    ).pipe(
      filter(isNotEmpty),
      map(riskManagers => uniqBy(riskManagers, 'user_id')),
      switchMap(riskManagers => this.graphService.getGraphUsers(riskManagers.map(riskManager => riskManager.user_email_address))),
      tap(riskManagers => this.patchState({ riskManagers, loading: false }))
    );
  }

  private isGovernanceContact(governanceCoordinator: GovernanceCoordinator): boolean {
    return governanceCoordinator.contact_type === this.governanceContactType;
  }
}
