import { Component } from '@angular/core';

import { version } from '../../../environments/version';

@Component({
  selector: 'app-commit-version',
  template: ` <small>Commit Hash: {{ commitHash }}</small> `,
  standalone: true,
})
export class VersionComponent {
  readonly commitHash = version?.hash;
}
