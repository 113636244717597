import { RiskCaseType } from '@shared/models';

import { CaseFilterType } from '../model/case-filter-type.model';
import { HomeTabType } from '../model/home-config.model';
import { UserSettingsEvent } from '../model/user-settings-event.enum';

export const selfIdentifiedLodId = 213;
export const secondLineIdentifiedLodId = 214;
export const thirdLineIdentifiedLodId = 215;
export const lowImpactRatingId = 22;
export const mediumImpactRatingId = 21;
export const moderateImpactRatingId = 20;
export const elevatedImpactRatingId = 19;
export const highImpactRatingId = 18;

export enum StatusId {
  EVENT_DRAFT = 6,
  EVENT_DRAFT_PENDING_REVIEW = 7,
  EVENT_PRE_DRAFT = 37,
  EVENT_OPEN = 8,
  EVENT_CLOSED = 10,
  EVENT_CANCELLED = 11,
  ISSUE_DRAFT = 23,
  ISSUE_DRAFT_PENDING_REVIEW = 24,
  ISSUE_PRE_DRAFT = 38,
  ISSUE_OPEN = 25,
  ISSUE_CLOSED = 30,
  ISSUE_CANCELLED = 36,
}

export type HomeLabel = 'Events' | 'Issues' | 'My To-Dos' | 'RCSAs';

export const RCSA_TAB_PARAM_MARK = 'rcsaTab';

export const caseFilterTypeToUserSettingsEvent: Record<CaseFilterType, Record<HomeTabType, UserSettingsEvent>> = {
  [CaseFilterType.MY]: {
    events: UserSettingsEvent.MY_CASES_EVENT,
    issues: UserSettingsEvent.MY_CASES_ISSUE,
    rcsas: UserSettingsEvent.RCSAS,
    todos: UserSettingsEvent.MY_TODOS,
  },
  [CaseFilterType.ALL]: {
    events: UserSettingsEvent.ALL_CASES_EVENT,
    issues: UserSettingsEvent.ALL_CASES_ISSUE,
    rcsas: UserSettingsEvent.RCSAS,
    todos: UserSettingsEvent.MY_TODOS,
  },
};

export enum HomePath {
  todos = 'todos',
  events = 'events',
  issues = 'issues',
  rcsas = 'rcsas',
}

export const homeTabTypeToFilterHeader: Record<HomeTabType, string> = {
  todos: '',
  rcsas: `RCSA filter`,
  events: 'Event filter',
  issues: 'Issue filter',
};

export const homeTabTypeToRiskCaseType: Partial<Record<HomeTabType, RiskCaseType>> = {
  issues: 'Issue',
  events: 'Event',
};

export const homeTabTypeToLabel: Record<HomeTabType, HomeLabel> = {
  events: 'Events',
  issues: 'Issues',
  todos: `My To-Dos`,
  rcsas: `RCSAs`,
};

export const homePathToLabel: Record<HomePath, HomeLabel> = {
  [HomePath.events]: 'Events',
  [HomePath.issues]: 'Issues',
  [HomePath.todos]: `My To-Dos`,
  [HomePath.rcsas]: `RCSAs`,
};
