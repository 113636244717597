import { isEqual, isBefore } from 'date-fns';

import { isValidDate } from '@shared/utils';

export const isBeforeDate = (date1: number | Date, date2: number | Date): boolean => {
  if (!isValidDate(date1) || !isValidDate(date2)) {
    return false;
  }

  if (typeof date1 !== typeof date2) {
    console.warn(`'isBeforeDate': Type of dates are not matching. date1: ${typeof date1}, date2: ${typeof date2}`);
  }

  return isEqual(date1, date2) || isBefore(date1, date2);
};
