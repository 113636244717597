import { inject, Injectable } from '@angular/core';
import { EnumOptionAdapter } from '@shared/adapters/enum-option.adapter';
import { RiskCaseEnum } from '@shared/constants/constants';
import { EnumService as OneormEnumerationsService } from 'oneorm-api-http-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Option } from '@shared/models';

@Injectable()
export class EnumService {
  private readonly service = inject(OneormEnumerationsService);

  getEnumOptions(type: RiskCaseEnum): Observable<Option[]> {
    return this.service.getEnumItems(type).pipe(
      map(data => data?.data),
      map(data => EnumOptionAdapter.fromDto(data))
    );
  }
}
