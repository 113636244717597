import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatChipListbox, MatChipOption, MatChipRemove } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';

import { Option } from '@shared/models';

@Component({
  selector: 'app-filter-chips',
  template: `
    <mat-chip-listbox [selectable]="false">
      @for (option of options; track option.id) {
        <mat-chip-option class="blue" (removed)="onChipRemoved(option)">
          {{ option.label }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-option>
      }
    </mat-chip-listbox>
  `,
  standalone: true,
  imports: [MatChipListbox, MatChipOption, MatChipRemove, MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterChipsComponent<T> {
  @Input({
    required: true,
  })
  options: Option<T>[];
  @Output() chipRemoved = new EventEmitter<Option<T>>();

  onChipRemoved(option: Option<T>): void {
    this.chipRemoved.emit(option);
  }
}
