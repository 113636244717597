import { Injectable } from '@angular/core';
import { IssueRatingEnumAdapter } from '@shared/adapters/issue-rating-enum.adapter';
import { IssuesStatusEnumAdapter } from '@shared/adapters/issues-status-enum.adapter';
import { LodEnumAdapter } from '@shared/adapters/lod-enum.adapter';
import { RiskCaseEnum } from '@shared/constants/constants';
import { HomeEnumService } from '@shared/services/home-enum.service';
import { EnumService } from 'oneorm-api-http-client';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IssuesCheckboxFilter } from '../../home/model/issues-filter.model';
import { FilterCheckboxGroupModel } from '../../rcsa/filter/filter-checkbox-group.model';

@Injectable()
export class IssuesEnumService extends HomeEnumService<IssuesCheckboxFilter> {
  constructor(private readonly enumService: EnumService) {
    super();
  }

  override getCheckboxFilters(): Observable<Record<IssuesCheckboxFilter, FilterCheckboxGroupModel>> {
    return forkJoin([
      this.enumService.getEnumItems(RiskCaseEnum.ISSUE_STATUS).pipe(
        map(data => data?.data),
        map(data => IssuesStatusEnumAdapter.fromDto(data))
      ),
      this.enumService.getEnumItems(RiskCaseEnum.ISSUE_RATING).pipe(
        map(data => data?.data),
        map(data => IssueRatingEnumAdapter.fromDto(data))
      ),
      this.enumService.getEnumItems(RiskCaseEnum.LOD).pipe(
        map(data => data?.data),
        map(data => LodEnumAdapter.fromDto(data))
      ),
    ]).pipe(
      map(([{ status }, { impactRating }, { identifyingLod }]) => ({
        status,
        impactRating,
        identifyingLod,
      }))
    );
  }
}
