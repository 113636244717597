import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

export const ROUTES = {
  rcsa: 'rcsa',
  wizard: {
    root: 'wizard',
    selection: 'selection',
    preDraft: {
      root: 'pre-draft',
      description: 'description',
      identification: 'identification',
      rcsa: 'rcsa',
      risk: 'risk',
      responsibilities: 'responsibilities',
      confirmation: 'confirmation',
    },
    draft: {
      root: 'draft',
      businessImpact: 'business-impact',
      legalEntities: 'legal-entities',
      outsourcing: 'outsourcing',
      monetaryImpact: 'monetary-impact',
      additionalInformations: 'additional-informations',
      review: 'review',
    },
  },
  home: '',
} as const;

const routes: Routes = [
  {
    path: ROUTES.rcsa,
    loadChildren: () => import('./rcsa/rcsa.module').then(x => x.RCSAModule),
    data: { title: 'RCSAs' },
    canActivate: [MsalGuard],
  },
  {
    path: ROUTES.wizard.root,
    loadChildren: () => import('./wizard/routes').then(x => x.wizardRoutes),
  },
  {
    path: ROUTES.home,
    loadChildren: () => import('./home/home.module').then(x => x.HomeModule),
    data: { title: 'Home' },
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
