import { Type, NO_ERRORS_SCHEMA } from '@angular/core';
import { TestBed, TestModuleMetadata } from '@angular/core/testing';

/**
 * Configures a TestBed with given parameters, applying shallow configurations for specified components.
 *
 * @param {TestModuleMetadata} moduleConfig - The base configuration for the testing module.
 * @param {Type<unknown>[]} shallowComponents - Components to be configured for shallow rendering.
 * @param imports - imports needed for shallow components
 */
export async function configureShallowTestBed(
  moduleConfig: TestModuleMetadata,
  shallowComponents: Type<unknown>[],
  imports: (Type<unknown> | ReadonlyArray<unknown>)[] = []
): Promise<TestBed> {
  const testBed = TestBed.configureTestingModule(moduleConfig);

  shallowComponents.forEach(component => {
    testBed.overrideComponent(component, {
      set: {
        imports,
        schemas: [NO_ERRORS_SCHEMA],
      },
    });
  });

  return testBed;
}
