import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RiskCasesAdapter } from '@shared/adapters/risk-cases.adapter';
import { RiskCaseType } from '@shared/models/risk-case-type.model';
import { RiskCaseViewFE } from '@shared/models/risk-case-view.model';
import { HomeService } from '@shared/services/home.service';
import { DEFAULT_LIMIT_SIZE, DEFAULT_PAGE_OFFSET } from '@styles/p-table/constants';
import { Issue, IssueService, OpEvent, OpEventService, RiskCasesViewService } from 'oneorm-api-http-client';
import { RiskCaseViewsListResponse } from 'oneorm-api-http-client/model/riskCaseViewsListResponse';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { extractEtagValue, isNil } from '@shared/utils';

import { CaseFilterType } from '../../home/model/case-filter-type.model';
import { TableData } from '../../home/model/table-data.model';

export interface GetRiskCaseViewsParams {
  creatorId?: string;
  ownerId?: string;
  delegateId?: string;
  rcsaOwnerId?: string;
  riskManagerId?: string;
  govCoordinatorId?: string;
  actionItemOwnerId?: string;
  actionItemDelegateId?: string;
  className: string;
  statusIds?: number[];
  globalFunction?: string;
  issueSourceId?: number;
  lodIds?: number[];
  impactRatingIds?: number[];
  typeIds?: number[];
  fullText?: string;
  fullTextCols?: string[];
  orderBy?: string;
  orderAsc?: boolean;
  pageSize?: number;
  pageOffset?: number;
}

const DEFAULT_SORT_FIELD = 'rc_source_name' as keyof RiskCaseViewFE;

function mapToFeModel(): (source$: Observable<RiskCaseViewsListResponse>) => Observable<TableData<RiskCaseViewFE[]>> {
  return source$ => source$.pipe(map(v => ({ data: RiskCasesAdapter.fromDto(v.data), totalRows: v.total_rows })));
}

@Injectable({
  providedIn: 'root',
})
export class RiskCasesService extends HomeService<RiskCaseViewFE[], GetRiskCaseViewsParams> {
  constructor(
    private readonly riskCasesService: RiskCasesViewService,
    private readonly issuesService: IssueService,
    private readonly eventsService: OpEventService
  ) {
    super();
  }

  getIssue(id: number): Observable<HttpResponse<Issue>> {
    return this.issuesService.getIssueById(id, 'response', false, {
      httpHeaderAccept: 'application/json',
    });
  }

  getEvent(id: number): Observable<HttpResponse<OpEvent>> {
    return this.eventsService.getOpEventById(id, 'response', false, {
      httpHeaderAccept: 'application/json',
    });
  }

  getAllRiskCases(params: GetRiskCaseViewsParams): Observable<TableData<RiskCaseViewFE[]>> {
    return this.riskCasesService
      .getRiskCaseViews(
        params?.className,
        params?.creatorId,
        params?.ownerId,
        params?.delegateId,
        params?.rcsaOwnerId,
        params?.riskManagerId,
        params?.govCoordinatorId,
        params?.actionItemOwnerId,
        params?.actionItemDelegateId,
        params?.statusIds,
        params?.globalFunction,
        params?.issueSourceId,
        params?.lodIds,
        params?.impactRatingIds,
        params?.typeIds,
        params?.fullText ?? '',
        params?.fullTextCols ?? [],
        params?.orderBy ?? DEFAULT_SORT_FIELD,
        params?.orderAsc ?? false,
        params?.pageSize ?? DEFAULT_LIMIT_SIZE,
        params?.pageOffset ?? DEFAULT_PAGE_OFFSET
      )
      .pipe(mapToFeModel());
  }

  getMyRiskCases(params: GetRiskCaseViewsParams): Observable<TableData<RiskCaseViewFE[]>> {
    return this.riskCasesService
      .getMyRiskCaseViews(
        params?.className,
        params?.creatorId,
        params?.ownerId,
        params?.delegateId,
        params?.rcsaOwnerId,
        params?.riskManagerId,
        params?.govCoordinatorId,
        params?.actionItemOwnerId,
        params?.actionItemDelegateId,
        params?.statusIds,
        params?.globalFunction,
        params?.issueSourceId,
        params?.lodIds,
        params?.impactRatingIds,
        params?.typeIds,
        params?.fullText ?? '',
        params?.fullTextCols ?? [],
        params?.orderBy ?? DEFAULT_SORT_FIELD,
        params?.orderAsc ?? false,
        params?.pageSize ?? DEFAULT_LIMIT_SIZE,
        params?.pageOffset ?? DEFAULT_PAGE_OFFSET
      )
      .pipe(mapToFeModel());
  }

  getData(
    caseType?: RiskCaseType,
    type?: CaseFilterType,
    params?: Omit<GetRiskCaseViewsParams, 'className'>
  ): Observable<TableData<RiskCaseViewFE[]>> {
    if (isNil(caseType)) {
      throw new Error('RiskCaseType must be specified for ' + RiskCasesService.name);
    }

    if (isNil(type)) {
      throw new Error('CaseFilterType must be specified for ' + RiskCasesService.name);
    }

    if (type === CaseFilterType.ALL) {
      return this.getAllRiskCases({ ...params, className: caseType });
    }
    return this.getMyRiskCases({ ...params, className: caseType });
  }

  deleteIssue(id: number, etag: string): Observable<void> {
    return this.issuesService.removeIssue(id, extractEtagValue(etag));
  }

  deleteEvent(id: number, etag: string): Observable<void> {
    return this.eventsService.removeOpEvent(id, extractEtagValue(etag));
  }

  updateIssue(id: number, etag: string, issue: Issue, observe?: 'body'): Observable<Issue>;
  updateIssue(id: number, etag: string, issue: Issue, observe: 'response'): Observable<HttpResponse<Issue>>;
  updateIssue(id: number, etag: string, issue: Issue, observe?: 'body' | 'response'): Observable<Issue | HttpResponse<Issue>> {
    if (isNil(observe) || observe === 'body') {
      return this.issuesService.updateIssue(id, extractEtagValue(etag), issue, observe ?? 'body') as Observable<Issue>;
    }

    return this.issuesService.updateIssue(id, extractEtagValue(etag), issue, observe ?? 'response') as Observable<HttpResponse<Issue>>;
  }

  updateEvent(id: number, etag: string, event: OpEvent, observe?: 'body'): Observable<OpEvent>;
  updateEvent(id: number, etag: string, event: OpEvent, observe: 'response'): Observable<HttpResponse<OpEvent>>;
  updateEvent(id: number, etag: string, event: OpEvent, observe?: 'body' | 'response'): Observable<OpEvent | HttpResponse<OpEvent>> {
    if (isNil(observe) || observe === 'body') {
      return this.eventsService.updateOpEvent(id, extractEtagValue(etag), event, observe ?? 'body') as Observable<OpEvent>;
    }

    return this.eventsService.updateOpEvent(id, extractEtagValue(etag), event, observe ?? 'response') as Observable<HttpResponse<OpEvent>>;
  }

  createEvent(event: OpEvent): Observable<HttpResponse<OpEvent>> {
    return this.eventsService.createOpEvent(event, 'response', false, {
      httpHeaderAccept: 'application/json',
    });
  }

  createIssue(issue: Issue): Observable<HttpResponse<Issue>> {
    return this.issuesService.createIssues(issue, 'response', false, {
      httpHeaderAccept: 'application/json',
    });
  }
}
