import { Adapter } from '@shared/adapters/adapter';
import { RiskCaseEnumItem } from 'oneorm-api-http-client/model/riskCaseEnumItem';

import { findEnumItem, getIssuesRiskCaseStatusFilter } from '@shared/utils';

import { IssuesCheckboxFilter } from '../../home/model/issues-filter.model';
import { FilterCheckboxGroupModel } from '../../rcsa/filter/filter-checkbox-group.model';

const DRAFT_ID = 23;
const OPEN_ID = 25;
const COMPLETED_ID = 30;
const CANCELLED_ID = 36;

interface IssuesStatusEnumItem {
  draftEnumItem: RiskCaseEnumItem;
  openEnumItem: RiskCaseEnumItem;
  completedEnumItem: RiskCaseEnumItem;
  cancelledEnumItem: RiskCaseEnumItem;
}

const FILTERS: Record<keyof IssuesStatusEnumItem, number> = {
  draftEnumItem: DRAFT_ID,
  openEnumItem: OPEN_ID,
  completedEnumItem: COMPLETED_ID,
  cancelledEnumItem: CANCELLED_ID,
};

export abstract class IssuesStatusEnumAdapter extends Adapter<
  Record<IssuesCheckboxFilter.status, FilterCheckboxGroupModel>,
  RiskCaseEnumItem[]
> {
  static fromDto(dto: RiskCaseEnumItem[]): Record<IssuesCheckboxFilter.status, FilterCheckboxGroupModel> {
    const { draftEnumItem, openEnumItem, completedEnumItem, cancelledEnumItem } = Object.keys(FILTERS).reduce<IssuesStatusEnumItem>(
      (result, key) => {
        result[key as keyof IssuesStatusEnumItem] = findEnumItem(dto, FILTERS[key as keyof IssuesStatusEnumItem]);
        return result;
      },
      {
        draftEnumItem: undefined,
        openEnumItem: undefined,
        completedEnumItem: undefined,
        cancelledEnumItem: undefined,
      } as IssuesStatusEnumItem
    );

    return getIssuesRiskCaseStatusFilter(draftEnumItem, openEnumItem, completedEnumItem, cancelledEnumItem);
  }
}
