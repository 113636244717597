import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SubscriptionVariablesService } from '@shared/services';
import { uniqBy, isNotNil } from '@shared/utils';

export interface Breadcrumb {
  label: string;
  url?: string;
  name?: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  subscription: Subscription = new Subscription();
  breadcrumbs: Breadcrumb[];

  constructor(
    private readonly subscriptionVariablesService: SubscriptionVariablesService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    const breadcrumb: Breadcrumb = {
      label: 'Home',
      url: '',
    };
    this.router.events.pipe(filter(event => event instanceof ActivationEnd)).subscribe(() => {
      // set breadcrumbs
      const root: ActivatedRoute = this.route.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
      this.breadcrumbs = uniqBy([breadcrumb, ...this.breadcrumbs], 'label');
      if (this.subscriptionVariablesService.breadcrumbValues) {
        this.updateBreadcrumbs(this.subscriptionVariablesService.breadcrumbValues);
      }
    });
  }

  ngOnInit(): void {
    this.subscription.add(this.subscriptionVariablesService.changeBreadcrumb.subscribe(breadcrumbs => this.updateBreadcrumbs(breadcrumbs)));
  }

  goOut(breadcrumb: Breadcrumb): void {
    if (breadcrumb.label === 'RCSA') {
      this.router.navigate(['/rcsas']);
      return;
    }

    if (breadcrumb.label === 'Home') {
      this.router.navigate(['/']);
      return;
    }

    this.router.navigateByUrl(breadcrumb.url);
  }

  updateBreadcrumbs(breadcrumbs: Breadcrumb): void {
    let obj = this.breadcrumbs.find(o => o.label === breadcrumbs.label);
    if (isNotNil(obj)) {
      obj.label = breadcrumbs.name;
    }
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'title';
    // get the child routes
    const children: ActivatedRoute[] = route.children;
    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }
    // iterate over each children
    for (const child of children) {
      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }
      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      // append route URL to URL
      url += `/${routeURL}`;
      // add breadcrumb
      const breadcrumb: Breadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB] as string,
        url,
      };
      breadcrumbs.push(breadcrumb);
      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }
}
