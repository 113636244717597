import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PopoverActionsDirective } from '@shared/directives/popover-actions.directive';

import { PopoverContainerComponent } from '@shared/components';
import { PopoverService } from '@shared/services';

@Component({
  selector: 'app-filter-popover-content',
  template: `
    <app-popover-container>
      <div class="mat-headline-4 header-label">{{ headerLabel }}</div>
      <ng-content></ng-content>

      <popover-actions>
        <ng-content select="button[secondary-button]"></ng-content>
        <button
          class="mr-3 borderless"
          (click)="onPopoverCloseButtonClicked()"
          color="primary"
          mat-stroked-button
          triggers="pointerenter:pointerout"
        >
          <span class="text-bolder">Close</span>
        </button>
      </popover-actions>
    </app-popover-container>
  `,
  standalone: true,
  styles: [
    `
      :host {
        display: contents;
      }

      .header-label {
        margin-bottom: 1rem;
      }

      popover-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    `,
  ],
  imports: [PopoverContainerComponent, PopoverActionsDirective, AsyncPipe, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPopoverContentComponent {
  @Input({
    required: true,
  })
  headerLabel: string;

  constructor(private readonly popoverService: PopoverService) {}

  onPopoverCloseButtonClicked(): void {
    this.popoverService.closePopover();
  }
}
