import { Pipe, PipeTransform } from '@angular/core';

import { isNotEmpty } from '@shared/utils';

@Pipe({
  standalone: true,
  name: 'isNotEmpty',
})
export class IsNotEmptyPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/ban-types
  transform<T>(value?: T | null): boolean {
    return isNotEmpty(value);
  }
}
