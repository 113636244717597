import { Pipe, PipeTransform } from '@angular/core';

import { RcsaViews } from '@shared/models';
import { isIssueOrEventInDraftForDays } from '@shared/utils';

@Pipe({
  standalone: true,
  name: 'isInDraftForDays',
})
export class IsIssueOrEventInDraftForDaysPipe implements PipeTransform {
  transform(row: RcsaViews): boolean {
    return isIssueOrEventInDraftForDays(row);
  }
}
