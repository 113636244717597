import { Pipe, PipeTransform } from '@angular/core';

import { isEmpty } from '@shared/utils';

@Pipe({
  name: 'safeHtml',
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
  private readonly htmlLeftBracket = '<';
  private readonly htmlRightBracket = '>';
  transform(value: string): string {
    if (isEmpty(value) || (!value.includes(this.htmlLeftBracket) && !value.includes(this.htmlRightBracket))) {
      return value;
    }
    return value.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }
}
