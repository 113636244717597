import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { isNil, isNumber, unixToFormattedDate } from '@shared/utils';

export function formatDateToOneOrmDate(value: unknown): string {
  if (isNil(value)) {
    return '';
  }

  let val: unknown = value;
  if (isNumber(value)) {
    val = unixToFormattedDate(value);
  }
  return formatDate(val as string | number | Date, 'dd MMM yyyy', 'en-US');
}

@Pipe({
  name: 'oneOrmDate',
  standalone: true,
})
export class OneOrmDatePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/ban-types
  transform(value: unknown): string {
    return formatDateToOneOrmDate(value);
  }
}
