// TODO: Remove once all duplications will be removed
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';

export function navigateToCurrentRoute(
  router: Router,
  route: ActivatedRoute,
  queryParams: Params,
  paramsHandling: QueryParamsHandling = 'merge'
): void {
  router.navigate([], {
    relativeTo: route,
    queryParams,
    queryParamsHandling: paramsHandling,
  });
}
