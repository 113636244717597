export enum EventsAutocompleteFilter {
  creator = 'creator',
  owner = 'owner',
  delegate = 'delegate',
  rcsa_owner = 'rcsa_owner',
  risk_manager = 'risk_manager',
  governanceCoordinator = 'governanceCoordinator',
  bu_gf = 'bu_gf',
}

export enum EventsCheckboxFilter {
  type = 'type',
  status = 'status',
}

export enum EventsTypeCheckboxFilter {
  incident = 'incident',
  loss = 'loss',
}

export enum EventsTypeCheckboxFilterValue {
  incident = 'Incident (no cash out)',
  loss = 'Loss',
}
