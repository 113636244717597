import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  template: ``,
  styles: [
    `
      :host {
        width: var(--skeleton-width);
        height: var(--skeleton-height);
        border-radius: var(--skeleton-border-radius);
        margin: var(--skeleton-margin);

        display: block;
        background: rgb(239, 241, 246) no-repeat;

        animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        animation-delay: 0.5s;
      }

      @keyframes pulse {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }
    `,
  ],
  standalone: true,
})
export class SkeletonLoaderComponent implements OnInit {
  width: string;
  height: string;
  borderRadius: string;
  margin: string;
  className: string;

  constructor(private readonly host: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    const host = this.host.nativeElement;

    if (this.className) {
      host.classList.add(this.className);
    }

    host.style.setProperty('--skeleton-width', this.width ?? '100%');
    host.style.setProperty('--skeleton-height', this.height ?? '20px');
    host.style.setProperty('--skeleton-border-radius', this.borderRadius ?? '4px');
    host.style.setProperty('--skeleton-margin', this.margin ?? '0');
  }
}
