<div class="show-more-position-parent" (click)="textClick()">
  <div class="show-more-container pb-0">
    <div
      class="show-more expand"
      *ngIf="text"
      [innerHTML]="text"
      [noOfLines]="noOfLines"
      [showMoreTogget]="hideToggleFullText"
      (showMoreView)="showMoreWork($event)"
      appShowMore
    ></div>

    <div
      class="show-more expand"
      *ngIf="listData"
      [noOfLines]="noOfLines"
      [showMoreTogget]="hideToggleFullText"
      (showMoreView)="showMoreWork($event)"
      appShowMore
    >
      <div
        *ngFor="let item of listData.list"
        [ngClass]="{ 'modal-opener text-underline': clickedItem }"
        (click)="$event.stopPropagation(); itemClick(item)"
      >
        {{ showItem(item, listData.nameShow) }}

        <span class="draft-rc" *ngIf="item.rc_status && item.rc_status === 'Draft'"> (draft)</span>
        <span class="draft-rc" *ngIf="item.risk_case_status_name && item.risk_case_status_name === 'Draft'"> (draft)</span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="text || listData">
    <div class="flex-endX-startY mt-4" *ngIf="hideToggle">
      <span
        class="Mid_Blue text-bold cursor-pointer show-more-position text-underline"
        (click)="$event.stopPropagation(); showMoreToggelButton()"
      >
        {{ hideToggleFullText ? '... show less' : '... show more' }}
      </span>
    </div>
  </ng-container>
</div>
