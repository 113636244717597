import { Component, inject, OnInit } from '@angular/core';

import { UserStore } from '@core/authorization';
import { DrawerService } from '@core/drawer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'orm-client';
  isIframe = false;
  isMobile = false;

  drawerOpened$ = this.drawerService.opened$;
  drawerMode$ = this.drawerService.mode$;

  private readonly store = inject(UserStore);

  constructor(private readonly drawerService: DrawerService) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
