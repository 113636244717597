import { Directive, HostBinding, Input } from '@angular/core';
import { isBeforeDate } from '@shared/utils/date/is-before-date';
import { getTime } from 'date-fns';

@Directive({
  selector: '[appOverdueCellColor]',
  standalone: true,
})
export class OverdueCellColorDirective {
  @Input() value: unknown;
  @Input() type: 'date' | 'number';

  @HostBinding('class.sr-negative-text') get negativeColorClassApplied(): boolean {
    if (this.type === 'date') {
      return isBeforeDate(this.value as number | Date, this.getCurrentTimeInSeconds());
    }
    return (this.value as number) <= 0;
  }

  private getCurrentTimeInSeconds(): number {
    return getTime(new Date()) / 1000;
  }
}
