import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SkeletonLoaderDirective } from '@shared/directives';
import { IsTruePipe } from '@shared/pipe';

@Component({
  selector: 'app-selection-tree-defer-loading-skeleton',
  template: `
    <div class="container">
      @for (_ of skeletons; track $index) {
        <div class="item" *skeleton="true; height: height; width: width; borderRadius: borderRadius"></div>
      }
    </div>
  `,
  styles: [
    `
      .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IsTruePipe, SkeletonLoaderDirective],
})
export class SelectionTreeDeferLoadingSkeletonComponent {
  readonly height = '48px';
  readonly width = '250px';
  readonly borderRadius = '16px';
  readonly skeletons = Array<number>(10).fill(0);
}
