import { Pipe, PipeTransform } from '@angular/core';

import { isNotNil } from '@shared/utils';

@Pipe({
  standalone: true,
  name: 'isNotNil',
})
export class IsNotNilPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/ban-types
  transform<T>(value: T | string | undefined | null): boolean {
    return isNotNil(value);
  }
}
