import { booleanAttribute, ChangeDetectionStrategy, Component, EventEmitter, Input, numberAttribute, Output } from '@angular/core';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton } from '@angular/material/radio';

import { SelectableDirective } from '../directives/selectable.directive';

export type SelectionType = 'single' | 'multi';

@Component({
  selector: 'app-selectable-card',
  template: `
    <mat-card [class.full-height]="fullHeight" [id]="id" [selected]="selected" (selectChange)="selectChange.emit($event)" appSelectable>
      <mat-card-header>
        <mat-card-title class="mat-headline-3"> <ng-content select="[card-title]" /> </mat-card-title>
        <div class="spacer"></div>
        @if (selectionType === 'single') {
          <mat-radio-button [checked]="selected" color="primary"></mat-radio-button>
        } @else {
          <mat-checkbox [checked]="selected" color="primary"></mat-checkbox>
        }
      </mat-card-header>
      <mat-card-content>
        <ng-content></ng-content>
      </mat-card-content>
    </mat-card>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCard, SelectableDirective, MatCardHeader, MatCardTitle, MatRadioButton, MatCardContent, MatCheckbox],
})
export class SelectableCardComponent {
  @Input({
    transform: booleanAttribute,
  })
  fullHeight = false;
  @Input() selectionType: 'single' | 'multi' = 'single';
  @Input({
    transform: numberAttribute,
  })
  id: number;
  @Input({
    transform: booleanAttribute,
  })
  selected: boolean;
  @Output() selectChange = new EventEmitter<number>();
}
