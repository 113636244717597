import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PopoverService } from '@shared/services';

@Directive({
  selector: '[appPopoverTrigger]',
  standalone: true,
})
export class PopoverDirective implements OnDestroy, OnInit {
  @Input()
  appPopoverTrigger!: TemplateRef<object>;

  @Input()
  closeOnClickOutside: boolean = true;

  @Output()
  isOpen = new EventEmitter<boolean>();

  private readonly unsubscribe = new Subject();
  private overlayRef!: OverlayRef;
  private readonly maxHeight: 700;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly overlay: Overlay,
    private readonly vcr: ViewContainerRef,
    private readonly popoverService: PopoverService
  ) {}

  @HostListener('click') onClick(): void {
    this.attachOverlay();
  }

  ngOnInit(): void {
    this.createOverlay();
    this.popoverService.state$.subscribe(resp => {
      if (resp) {
        this.detachOverlay();
      }
    });
  }

  ngOnDestroy(): void {
    this.detachOverlay();
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  private createOverlay(): void {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: '',
      maxHeight: this.maxHeight,
    });

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.closeOnClickOutside) {
          this.popoverService.closePopover();
        }
      });
  }

  private attachOverlay(): void {
    this.isOpen.emit(true);
    if (!this.overlayRef.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(this.appPopoverTrigger, this.vcr);

      this.overlayRef.attach(periodSelectorPortal);
    }
  }

  private detachOverlay(): void {
    this.isOpen.emit(false);
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
