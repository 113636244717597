import { Injectable, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged, fromEvent, Subject } from 'rxjs';

interface ResizeEvent {
  width: number;
  height: number;
}

@Injectable()
export class WindowResizeService {
  readonly resized$ = new Subject<ResizeEvent>();

  constructor(private readonly ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(debounceTime(500), distinctUntilChanged(), takeUntilDestroyed())
        .subscribe(e => {
          this.ngZone.run(() => {
            this.resized$.next({ width: (e.target as Window).innerWidth, height: (e.target as Window).innerHeight });
          });
        });
    });
  }
}
