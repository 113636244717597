import { format, fromUnixTime } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { environment } from '../../../../environments/environment';

/**
 * Transforms a unit (either a string, number or Date) into a formatted date string.
 *
 * @remarks
 * This function is useful for cases where you need to convert time units (represented as string, number)
 * into a human-readable formatted date string.
 *
 * This function assumes that the provided value represents a Unix timestamp if it's a number or a string that can be converted to a number.
 * It then converts this Unix timestamp to a JavaScript Date object,
 * adjusts it to the timezone set in the environment,
 * and finally formats it to a string using the format specified in the environment.
 *
 * @param value - A string, number that represents the date to be formatted.
 * `fromUnixTime` is used to convert strings and numbers into a Date.
 * @returns A string that represents the formatted date.
 *
 * @example
 * ```typescript
 * const formattedDate = unixToFormattedDate(1637193600); // returns a string representing the date in the specified format
 * ```
 */
export const unixToFormattedDate = (value: string | number): string => {
  const dateFromUnix = fromUnixTime(Number(value));
  const zonedDate = utcToZonedTime(dateFromUnix, environment.timeZone);
  return format(zonedDate, environment.timeFormat);
};
