import { AfterContentInit, ContentChildren, Directive, Input, OnDestroy, QueryList } from '@angular/core';
import { PTableRowDirective } from '@shared/directives/table-row.directive';
import { isNil } from '@shared/utils/object/is-nil';
import { isNotNil } from '@shared/utils/object/is-not-nil';
import { Table } from 'primeng/table';
import { mergeMap, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: 'p-table[appAutoScrollToLastElement]',
  standalone: true,
})
export class TableAutoScrollDirective implements AfterContentInit, OnDestroy {
  private static readonly scrollBehavior = 'smooth';

  @ContentChildren(PTableRowDirective, { descendants: true }) tableRows: QueryList<PTableRowDirective>;

  @Input() itemIndex?: number;

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly table: Table) {}

  ngAfterContentInit(): void {
    if (isNil(this.table)) {
      throw new Error('PTable must be defined when using TableAutoScrollDirective');
    }

    this.tableRows.changes
      .pipe(
        filter(isNotNil),
        map(rows => rows._results as PTableRowDirective[]),
        mergeMap(rows => rows),
        filter(row => row.nativeElement.rowIndex === this.itemIndex - 1),
        map(row => row.nativeElement.offsetTop),
        takeUntil(this.destroy$)
      )
      .subscribe(offset => {
        if (isNil(this.itemIndex)) {
          return;
        }

        this.table.scrollTo({
          top: offset,
          behavior: TableAutoScrollDirective.scrollBehavior,
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
