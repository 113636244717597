import { isNil } from '@shared/utils';

function getAllValuesAsString(object: unknown): string {
  let valuesString = '';
  if (object) {
    Object.values(object).forEach(value => {
      if (typeof value === 'object') {
        valuesString += getAllValuesAsString(value) + ' ';
      } else {
        valuesString += value.toString() + ' ';
      }
    });
  }
  return valuesString.toLowerCase().trim();
}

export function searchInObject(value: string | object | undefined | null, filter: string): boolean {
  if (isNil(value)) {
    return false;
  }

  const filterLower = filter.toLowerCase();

  if (typeof value === 'object') {
    const valuesString = getAllValuesAsString(value);
    return valuesString.includes(filterLower);
  } else if (typeof value === 'string') {
    return value.toLowerCase().includes(filterLower);
  }

  return false;
}
