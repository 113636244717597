<div class="container rightSideBar">
  <mat-card class="rightSideBarCard" appearance="raised">
    <mat-card-header class="w-100 flex-centerX-centerY">
      <strong>Do you need support?</strong>
      <span class="material-icons cursor-pointer big-icon" (click)="closeBar()"> close </span>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content class="cardContant" *ngrxLet="loadingContacts$; let loading">
      <ng-template #loadingTemplate>
        <div>
          <div class="my-2">
            <div *skeleton="true; height: '25px'; width: '170px'; margin: '0 0 0 27%'"></div>
          </div>
          <div class="container-Support my-1" *ngFor="let num of [1, 2, 3]">
            <div class="supportPhoto">
              <div *skeleton="true; height: '50px'; width: '50px'; borderRadius: '50%'; margin: '10px 10px'"></div>
            </div>
            <div class="supportData">
              <div *skeleton="true; height: '23px'; width: '170px'; repeat: 3; margin: '2px 0'"></div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-container *ngIf="loading | isFalse; else loadingTemplate">
        <div *ngIf="governanceCoordinators$ | async as governanceCoordinators">
          <div class="my-2">
            <div class="titleSupport">
              <strong>Your Governance Contact</strong>
            </div>
          </div>
          <div *ngFor="let item of governanceCoordinators; let i = index">
            <div
              class="showMoreLess pl-3"
              *ngIf="numberOfItems < governanceCoordinators.length && i === 3"
              (click)="numberOfItems = governanceCoordinators.length"
            >
              show more...
            </div>
            <div class="container-Support my-1" *ngIf="i < numberOfItems">
              <div class="supportPhoto">
                <img class="picture" *ngIf="item && item.picture" [src]="item.picture" alt="Support" />
                <mat-icon class="" [ngStyle]="getColor(item.presence)" matTooltip="{{ item.presence }}">{{
                  getPresenceIcon(item.presence)
                }}</mat-icon>
              </div>
              <div class="supportData">
                <div>
                  <label
                    ><strong>{{ item.displayName }}</strong></label
                  >
                </div>
                <div>
                  <label>{{ item.jobTitle }}</label>
                </div>
                <div class="flex-centerX w-50">
                  <mat-icon (click)="sendEmail(item.mail, item.givenName)" matTooltip="Send an email"> email </mat-icon>
                  <mat-icon
                    [matTooltipPosition]="'above'"
                    (click)="sendChatMessage(item.mail)"
                    matTooltip="Send a message via Microsoft Teams"
                  >
                    question_answer
                  </mat-icon>
                  <mat-icon
                    [matTooltipPosition]="'above'"
                    (click)="scheduleMeeting(item.mail)"
                    matTooltip="Schedule meeting via Microsoft Teams"
                  >
                    calendar_today
                  </mat-icon>
                </div>
              </div>
            </div>
            <div
              class="showMoreLess pl-3"
              *ngIf="governanceCoordinators.length > 3 && i === governanceCoordinators.length - 1 && numberOfItems > 3"
              (click)="numberOfItems = 3"
            >
              show less...
            </div>
          </div>
        </div>
        <div *ngIf="riskManagers$ | async as riskManagers">
          <div class="my-2">
            <div class="titleSupport"><strong>Your ORM Contact</strong></div>
          </div>
          <div *ngFor="let item of riskManagers; let i = index">
            <div
              class="showMoreLess pl-3"
              *ngIf="numberOfItemsL < riskManagers.length && i === 3"
              (click)="numberOfItemsL = riskManagers.length"
            >
              show more...
            </div>
            <div class="container-Support my-1" *ngIf="i < numberOfItemsL">
              <div class="supportPhoto">
                <img class="picture" [src]="item.picture" alt="Risk Managers" />
                <mat-icon class="" [ngStyle]="getColor(item.presence)" matTooltip="{{ item.presence }}">{{
                  getPresenceIcon(item.presence)
                }}</mat-icon>
              </div>
              <div class="supportData">
                <div>
                  <label
                    ><strong>{{ item.displayName }}</strong></label
                  >
                </div>
                <div>
                  <label>{{ item.jobTitle }}</label>
                </div>
                <div class="flex-centerX w-50">
                  <mat-icon (click)="sendEmail(item.mail, item.displayName)" matTooltip="Send an email">email </mat-icon>
                  <mat-icon
                    [matTooltipPosition]="'above'"
                    (click)="sendChatMessage(item.mail)"
                    matTooltip="Send a message via Microsoft Teams"
                  >
                    question_answer
                  </mat-icon>
                  <mat-icon
                    [matTooltipPosition]="'above'"
                    (click)="scheduleMeeting(item.mail)"
                    matTooltip="Schedule meeting via Microsoft Teams"
                  >
                    calendar_today
                  </mat-icon>
                </div>
              </div>
            </div>
            <div
              class="showMoreLess pl-3"
              *ngIf="riskManagers.length > 3 && i === riskManagers.length - 1 && numberOfItemsL > 3"
              (click)="numberOfItemsL = 3"
            >
              show less...
            </div>
          </div>
        </div>
        <!-- your compliance contact-->
        <div *ngIf="complianceContacts$ | async as compliances">
          <div class="my-2">
            <div class="titleSupport">
              <strong>Your Compliance Contact</strong>
            </div>
          </div>
          <div *ngFor="let item of compliances; let i = index">
            <div class="container-Support my-1" *ngIf="i < numberOfItemsL">
              <div class="supportPhoto">
                <img class="picture" [src]="item.picture" alt="Compliance" />
                <mat-icon class="" [ngStyle]="getColor(item.presence)" matTooltip="{{ item.presence }}">{{
                  getPresenceIcon(item.presence)
                }}</mat-icon>
              </div>
              <div class="supportData">
                <div>
                  <label
                    ><strong>{{ item.displayName }}</strong></label
                  >
                </div>
                <div>
                  <label>{{ item.jobTitle }}</label>
                </div>
                <div class="flex-centerX w-50">
                  <mat-icon (click)="sendEmail(item.mail, item.displayName)" matTooltip="Send an email">email </mat-icon>
                  <mat-icon
                    [matTooltipPosition]="'above'"
                    (click)="sendChatMessage(item.mail)"
                    matTooltip="Send a message via Microsoft Teams"
                  >
                    question_answer
                  </mat-icon>
                  <mat-icon
                    [matTooltipPosition]="'above'"
                    (click)="scheduleMeeting(item.mail)"
                    matTooltip="Schedule meeting via Microsoft Teams"
                  >
                    calendar_today
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="noContactsAvailable$ | async">
          <div class="my-5 flex-centerX">
            <div class=""><strong>No support contacts available</strong></div>
          </div>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
