import { Adapter } from '@shared/adapters/adapter';
import { RiskCaseEnumItem } from 'oneorm-api-http-client/model/riskCaseEnumItem';

import { findEnumItem, getEventsRiskCaseStatusFilter } from '@shared/utils';

import { EventsCheckboxFilter } from '../../home/model/events-filter.model';
import { FilterCheckboxGroupModel } from '../../rcsa/filter/filter-checkbox-group.model';

const DRAFT_ID = 6;
const OPEN_ID = 8;
const CLOSED_ID = 10;
const CANCELLED_ID = 11;

interface EventsStatusEnumItem {
  draftEnumItem: RiskCaseEnumItem;
  openEnumItem: RiskCaseEnumItem;
  closedEnumItem: RiskCaseEnumItem;
  cancelledEnumItem: RiskCaseEnumItem;
}

const FILTERS: Record<keyof EventsStatusEnumItem, number> = {
  draftEnumItem: DRAFT_ID,
  openEnumItem: OPEN_ID,
  closedEnumItem: CLOSED_ID,
  cancelledEnumItem: CANCELLED_ID,
};

export abstract class EventsStatusEnumAdapter extends Adapter<
  Record<EventsCheckboxFilter.status, FilterCheckboxGroupModel>,
  RiskCaseEnumItem[]
> {
  static fromDto(dto: RiskCaseEnumItem[]): Record<EventsCheckboxFilter.status, FilterCheckboxGroupModel> {
    const { draftEnumItem, openEnumItem, closedEnumItem, cancelledEnumItem } = Object.keys(FILTERS).reduce<EventsStatusEnumItem>(
      (result, key) => {
        result[key as keyof EventsStatusEnumItem] = findEnumItem(dto, FILTERS[key as keyof EventsStatusEnumItem]);
        return result;
      },
      {
        draftEnumItem: undefined,
        openEnumItem: undefined,
        closedEnumItem: undefined,
        cancelledEnumItem: undefined,
      } as EventsStatusEnumItem
    );

    return getEventsRiskCaseStatusFilter(draftEnumItem, openEnumItem, closedEnumItem, cancelledEnumItem);
  }
}
