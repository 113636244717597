import { ParamMap } from '@angular/router';
import { distinctUntilChanged, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { isEqual } from '@shared/utils';

import { FilterDropdownOption } from '../../models/filter-dropdown-option.model';

export function getQueryParam(
  source: Observable<ParamMap>,
  parameter: string,
  destroyed$: Observable<void>
): Observable<FilterDropdownOption> {
  return source.pipe(
    map(params => JSON.parse(params.get(parameter))),
    distinctUntilChanged(isEqual),
    takeUntil(destroyed$)
  );
}
