import { NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule } from '@azure/msal-angular';
import { provideAuth } from '@core/authorization/auth.provider';
import { RightSidenavComponent } from '@core/right-sidenav/right-sidenav.component';
import { OverlaySpinnerComponent } from '@shared/components/overlay-spinner/overlay-spinner.component';
import { ApiModule, BASE_PATH } from 'oneorm-api-http-client';
import { environment } from 'src/environments/environment';

import { CoreModule } from '@core/core.module';
import { FavIconService, GraphService, SubscriptionVariablesService, ThemeDetectionService, ToastService } from '@shared/services';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

function initializeThemeDetection(themeDetectionService: ThemeDetectionService) {
  return () => themeDetectionService.init();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ApiModule,
    MatSidenavModule,
    RightSidenavComponent,
    MsalModule,
    OverlaySpinnerComponent,
    NgOptimizedImage,
  ],
  providers: [
    GraphService,
    SubscriptionVariablesService,
    ToastService,
    { provide: BASE_PATH, useValue: environment.apiUrl },
    provideAuth(),
    FavIconService,
    ThemeDetectionService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeThemeDetection,
      deps: [ThemeDetectionService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
