import { InfoKey } from '@shared/constants/constants';

export const informationListsRCSA = [
  {
    title: 'RCSA',
    information: `
      The objective of the RCSA, is to provide transparency around the
            current risk and control environment, ongoing and recently completed
            issues and actions as well as operational events that have happened.
            <br>
            <b>
            Summary
            </b>
      <ul class='m-0'>
      <li>
      The RCSA is the primary instrument used by the RCSA Owners to
      periodically (re-) assess control effectiveness against identified
      risks.
      </li>
      <li>
   As a rule, RCSAs are allocated to department heads (Managing
              Director level).
              </li>
              <li>
              As a rule, RCSAs are allocated to department heads (Managing
                         Director level).
                         </li>
                         <li>
                         A quarterly sign-off is mandatory to confirm that the RCSA is
                         complete and up-to-date
                         </li>
                         <li>
                         With the sign-off, the RCSA owner confirms that
                         <ul class='m-0'>
                         <li>
                         All material risks have been identified and controls have been
                         assessed;
                         </li>
                         <li>
                         Control deficiencies are addressed with appropriate issues and
                         actions; and
                         </li>
                         <li>
                         All known operational events are recorded.
                         </li>
      </ul>
                         </li>
      </ul>
      <br>

      For details, please refer to the  <a href="https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000650.pdf" target="_blank" rel="noopener noreferrer">L4-RCSA Guidance document</a>.

      `,
  },
  {
    title: 'Dashboard',
    information: `
      The RCSA - Dashboard provides an overview over a specific RCSA showing
            exceptions requiring action and highlighting risk mitigation relevant
            observations.
      <br>
      <b>
      Exceptions requiring actions (upper row in the dashboard)
      </b>

      <ul class='m-0'>
                         <li>
                         Risks with not effective controls and no open issues to resolve
                         </li>
                         <li>
                         Events that have been reported but not submitted within 30 days

                         </li>
                         <li>
                         Overdue issues and issues that have been reported but not submitted within 30 days
                          </li>
      </ul>
      <b>
      Risk relevant observations (lower row in the dashboard)
      </b>

      <ul class='m-0'>
                         <li>
                         Number of risks with not effective, assessed or missing controls
                         </li>
                         <li>
                         Number of not effective controls

                         </li>
                         <li>
                         Events reported in the current reporting period
                         </li>
                         <li>
                         Issues due in the current reporting period
                         </li>
      </ul>
      `,
  },
  {
    title: 'Risks',
    information: `       The RCSA - Risk screen shows all risks within a selected RCSA, with
      all mitigating controls, open issues and open events
  <br>
  <b>
  Risk mitigation overview
  </b>

  <ul class='m-0'>
                   <li>
                   All risks within the selected RCSA                 </li>
                   <li>
                   Issues and events directly associated to each risk

                   </li>
                   <li>
                   Controls within the responsibility of the RCSA owner
                   </li>

                   <li>
                   Controls within the responsibility of other RCSA owners mitigating
                   risks of the selected RCSA                 </li>
                   <li>
                   Issues and events associated to each control
                   </li>
  </ul>
  <b>
  Highlighted Observations
  </b>

  <ul class='m-0'>
                   <li>
                   Risk for which there is no control                 </li>
                   <li>
                   Risks with not effective controls and no open issue to resolve
                   </li>
                   <li>
                   Controls assessed as not effective                 </li>
  </ul>
  `,
  },
  {
    title: 'Controls',
    information: `
      The RCSA - Controls screen contains various sub screens of which the
            Control assessment and the controls performed in other departments are the most
            relevant ones for the quarterly RCSA.
            <br>
      <b>
      Control assessment</b>

  <ul class='m-0'>
                   <li>
                   All controls within the responsibility of the RCSA owner, which have
                   to be reviewed and updated by the assigned control assessors once a
                   quarter.
                   </li>
  </ul>

  <b>
  Controls performed in other departments</b>

  <ul class='m-0'>
               <li>
               All controls mitigating risks within the own RCSA but are performed
              somewhere. The RCSA owner should be aware of the performance of
              these controls to ensure that own risks are mitigated appropriately.
               </li>
  </ul>

  <b>
  Control details</b>

  <ul class='m-0'>
               <li>
               Highlighting selected attributes for easier review

               </li>
  </ul>

  <b>
  Comparison with the Global Control Catalogue (GCC)</b>

  <ul class='m-0'>
               <li>
               List of all attributes that are defined in the GCC but can be
               updated in the RCSA.
               </li>
  </ul>
      `,
  },
  {
    title: 'Control Assessment',
    information: `
      Control Assessors perform the quarterly control self-assessment on
      this screen.
      <br>
      <b>
  Assessment Rating</b>


  <ul class='m-0'>
  <li class="mat-subtitle-1">
  <span class="sr-positive-text no-wrap mat-subtitle-1">"Effective"</span> - The
  control is designed to mitigate the risk and performed in accordance
  with its design. No errors identified.
  </li>
  <li class="mat-subtitle-1">
  <span class="sr-negative-text no-wrap mat-subtitle-1">"Not Effective" </span> -
  The control is not designed effectively to mitigate the risk, not
  operating effectively or is not implemented. 
  </li>
  <li class="mat-subtitle-1">
  <span class="sr-highlight-text no-wrap mat-subtitle-1">"Not Assessed" </span> - The
  control has not yet been assessed .
  </li>
  <li class="mat-subtitle-1">
  <span class="notApplicable mat-subtitle-1">"Not Applicable" </span> -
  The control is not needed in this RCSA and should be removed by ORM.
  </li>
  </ul>

  <b class="mat-headline-6">Assessment Commentary</b>
          <ul>
            <li class="mat-subtitle-1">
              Explain the rationale in support of the control effectiveness rating. It should indicate the activities performed to assess whether control was effective or not.
            </li>
            <li class="mat-subtitle-1">
              The assessment comment is <u>mandatory</u> for all control assessments.
            </li>
            <li class="mat-subtitle-1">
              Assessment comments need to be <u>updated</u> in subsequent assessments explaining any changes in the rating.
            </li>
          </ul>
          <b class="mat-headline-6">Evidence</b>
          <ul>
            <li class="mat-subtitle-1">
              Describe the specific evidence relevant for your assessment of the
              control performance.
            </li>
            <li class="mat-subtitle-1">
              ​Indicate where the evidence is stored.​
            </li>
             <li class="mat-subtitle-1">
              Controls without evidence are not considered effective.
            </li>
            <li class="mat-subtitle-1">
              Specific instructions regarding control documentation available in <a href="https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000577.pdf" target="_blank" rel="noopener noreferrer">Risk Control Instructions</a>.
            </li>
          </ul>
          <b class="mat-headline-6">Rating History</b>
          <ul>
            <li class="mat-subtitle-1">
              Shows the control assessment rating of the last 4 reporting periods
            </li>
          </ul>
          <b class="mat-headline-6">Update History</b>
          <ul>
            <li class="mat-subtitle-1">
              Indicates when and by whom the last assessment has been performed –
              this is triggered by pressing the update button.
            </li>
            <li class="mat-subtitle-1 sr-negative-text no-wrap">
              Important: indicating that a control has been assessed by
              <b>clicking the update button</b> is best
              practice and should also be done after each assessment, also in case
              where no update of the rating, assessment commentary or evidence was
              required
            </li>
          </ul>

    `,
  },
  {
    title: 'Events',
    information: `
      The RCSA – Event screen shows all events belonging to the selected
      RCSA.
      <br>
    <b class="mat-headline-6">Summary</b>
    <ul>
      <li class="mat-subtitle-1">
        The objective of operational event reporting is to enhance
        transparency of operational mishaps, errors or omissions, to learn
        from lessons and to prevent operational events from re-occurring.
        Recorded lessons learned form a knowledge base for risk takers and
        controllers.
      </li>
      <li class="mat-subtitle-1">
        All Swiss Re employees must report operational events when
        discovered if any of the following reporting thresholds or criteria
        applies:
        <ul>
          <li class="mat-subtitle-1">
            Cash-out exceeding a threshold of USD 10k;
          </li>
          <li class="mat-subtitle-1">
            Fraudulent activities (only when case is closed);
          </li>
          <li class="mat-subtitle-1">
            (Potential) Legal and regulatory consequences;
          </li>
          <li class="mat-subtitle-1">
            (Potential) Reputational damage for the company;
          </li>
          <li class="mat-subtitle-1">
            Financial misstatements such as Group US GAAP, Statutory GAAP or
            EVM; or
          </li>
          <li class="mat-subtitle-1">
            Lessons learned for the benefit of more than one functional
            area.
          </li>
        </ul>
      </li>
    </ul>
    <br>
      For details, please refer to the
      <a
        href="https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000647.pdf"
        target="_blank"
        rel="noopener noreferrer"
        >L4-Op Event Reporting Guidance document.</a
      >
    `,
  },
  {
    title: 'Issues',
    information: `
      The RCSA – Issues screen shows all issues belonging to the selected
      RCSA.
      <br>
    <b class="mat-headline-6">Summary</b>
    <ul>
      <li class="mat-subtitle-1">
        The objective of issue management is to focus management’s attention
        on areas that require improvements within their risk and control
        environment and take action to mitigate operational risks.
      </li>
      <li class="mat-subtitle-1">
        An issue describes a problem where a process or a control is
        nonexistent, is not working as designed or where there is an
        opportunity to strengthen an existing control. Corrective action
        plans are required to fix the issue within a timely manner in order
        to avoid a risk materializing/move outside the tolerance limits for
        Swiss Re.
      </li>
      <li class="mat-subtitle-1">
        Identified control deficiencies, missing controls and risk gaps
        requiring improvement as well as risk reviews, audit activities, and
        reported operational events are reasons for raising issues.
      </li>
    </ul>
    <h3 class="mat-subtitle-1">
      For details, please refer to the
      <a
        href="https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000646.pdf"
        target="_blank"
        rel="noopener noreferrer"
        >L4-Issue Management Guidance document.</a
      >
    </h3>
    `,
  },
];
export const informationListsIssue = [
  {
    infoKey: InfoKey.ISSUE_MANDATE,
    title: 'Mandate',
    information: `We assign all reported cases (Issues and Events) in Risk and Control Assessments (RCSA) to clearly define ownership to an organizational entity.
       <br>
       <span class="material-icons">
  arrow_right
  </span>
  Please confirm the Organisational Unit & RCSA where your case best belongs to or follow the wizard to choose a different one.
       <br>
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <div class='mt-3'>
       <b>
       What is an RCSA?
       </b>
       </div>
       <ul class='m-0'>
   <li>The Risk and Control Self-Assessment (also known as RCSA) provides an overview of all identified material operational risk exposures for a specific organisational unit, including all open issues and events.
   </li>
   <li>
   RCSA Owners periodically (re-) assess control effectiveness against identified material operational risk exposures.
  </li>
  </ul>
       `,
  },
  {
    infoKey: InfoKey.ISSUE_EVENT,
    title: 'Event or Issue',
    information: `We differentiate between things that have happened in order to learn from them and foster transparency; and things which need to be addressed to prevent them from happening in the future.
       <br>
       <span class="material-icons">
  arrow_right
  </span>
       Please select whether something <b>“has happened” (Event)</b> or describes a problem which
       <b>“needs to be addressed” (Issue)</b>.
       <br>
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <div class='mt-3'>
       <b>
       What is an Event?
       </b>
       </div>
       <ul class='m-0'>
       <li>
       An operational event is a risk which has materialised, I.e., something went wrong.
       </li>
   <li>
   Ongoing efforts after the initial reporting are primarily related to root cause analysis and the documentation of lessons learned.
   </li>
   <li>
   Should any corrective actions be identified to prevent a re-occurring they can also be raised as issues at a later stage and should not delay the initial reporting of the event.
   </li>
  </ul>
  <div class='mt-3'>
  <b>
  What is an Issue?
  </b>
  </div>
  <ul class='m-0'>
  <li>
  An issue describes a problem where a process or a control is non-existent, is not working as designed or where there is an opportunity to strengthen an existing control.
  </li>
  <li>
  Corrective action plans are required to fix the issue within a timely manner in order to avoid a risk materializing/move outside the tolerance limits for Swiss Re.
  </li>
  </ul>
       `,
  },
  {
    infoKey: InfoKey.ISSUE_DESCRIPTION,
    title: 'Description',
    information: `
       <div class='mt-3'>  <b>      Issue Description      </b>      </div>
       <ul class='m-0'>
       <li>
       This is the place to provide an initial description of the problem.
       </li>
   <li>
   Title and Detail Description can be updated as long as the issue is in status “draft”.
   </li>
  </ul>
  <div class='mt-3'>  <b>      Additional Information:      </b>      </div>
  <div class='mt-3'>  <b>     What makes a good issue title?      </b>      </div>
  <ul class='m-0'>
  <li>
  A good issue title can serve as a stand-alone title in a report or dashboard. It should be a meaningful short description of the problem.
  <br>
  Avoid wordy titles, abbreviations, codes, dates, locations, monetary amounts and references to policies, risks or controls.
  </li>
  <li>
  Critical information belongs into the detail description field.
  </li>
  </ul>
  <div class='mt-3'>  <b>     What makes a good detail description?  </b>      </div>
  <ul class='m-0'>
  <li>
  A good detail description allows a knowledgeable reader to understand the problem, it’s origination and how it impacts the risk mitigation, if not resolved in time.
  </li>
  <li>
  It also gives an impression on, what may happen where, and highlights potential consequences, should the related risks materialize.
  </li>
  <li>
  <b>
  Check Text Quality:
  </b>
   is a new experimental feature to help you in various aspects of writing the issue description. Starting small with limited functionality, we will improve it over time. Its use is optional and does not replace the issue owner's responsibility for writing an accurate event description.
  </li>
  </ul>
  `,
  },
  {
    infoKey: InfoKey.ISSUE_IDENTIFICATION,
    title: 'Identification',
    information: `
       <ul class='m-0'>
       <li>
       The distinction between self-identified and not self-identified issues is important. The self-identification of issues is considered pro-active and good risk-taking behavior.
       </li>
       <li>
       Issues identified and raised by a risk taker are considered "self-identified"; issues identified by a risk controlling or assurance function are considered "not self-identified".
       </li>
       </ul>
       <div class='mt-3'>  <b>      Additional Information:      </b>      </div>
       <ul class='m-0'>
       <li>
       Risk takers shall consider the following, before raising a "self-identified" issue:
       <ul class='m-0'>
       <li>
       If a risk controlling or an assurance function concludes and communicates that their observations do not merit raising an issue in GRACE; the risk taker is not allowed to raise a self-identified issue for addressing these observations.
       </li>
       <li>
       If the risk taker identifies additional gaps or weaknesses on top of such observations, then they should bring this to the attention of the embedded operational risk manager for discussion and agreement, before recording an issue in GRACE.
       </li>
       </ul>
       </li>
       <li>
       If you are not sure, approach your risk manager for guidance before submitting the issue for go live approval.
       </li>
       </ul>
       `,
  },
  {
    infoKey: InfoKey.ISSUE_RISK_CONTROL,
    title: 'Risk or Control',
    information: `
       Select Risk or Control
       <ul class='m-0'>
       <li>
       Issues can arise
       <br>
       <span class='mr-3'>a.</span> directly from not effective controls; or
       <br>
       <span class='mr-3'>b.</span>  have a wider scope where one or several risks are not adequately mitigated, or will not effective mitigated in future, if not addressed.
       <br>
       In case of control deficiencies, the issue must be raised against the ineffective control. In all other cases the issue shall be raised against the inadequately mitigated risk.
       <br>
       Should you realize while documenting the issues that you have chosen the wrong risk or control , you can still change it later.
       <br>
       Please do not hesitate to reach out to your governance coordinators and ORM experts for help.
       </li>
  </ul>
       <div class='mt-3'>   <b>  Additional Information:  </b>  </div>
       <div class='mt-3'>   <b> What is an operational risk?   </b>  </div>
       <ul class='m-0'>
       <li>
     Operational risk represents a potential economic loss, a financial reporting misstatement, a reputational or a regulatory compliance impact resulting from inadequate or failed internal processes, people and systems, or from external causes.
  </li>
  </ul>
  <div class='mt-3'>   <b> What is a control?
  </b>  </div>
  <ul class='m-0'>
  <li>
  A control is an activity performed at an entity, transaction or supervisory level, which mitigates one or more operational risks as defined in the Group Risk Register. An effective control ensures that material risks are adequately mitigated.
  </li>
  </ul>
     `,
  },
  {
    infoKey: InfoKey.ISSUE_ROLES,
    title: 'Roles',
    information: `
       There are several roles involved in the issue management process.
       <ul class='m-0'>
       <li>
       The <b>Issue Creator</b> (you) is the person who initially creates a new issue in One ORM.
       </li>
   <li>
   The <b>Issue Owner</b> is the person taking over from you to perform the risk impact assessment, define and agree on the issue resolution approach, complete the issue documentation and to manage the issue resolution.
   </li>
   <li>
   The <b>Issue Delegate</b> is the deputy of the issue owner.  They have the same rights and receives the same e-mail notifications.
   </li>
   <li>
   <b>Governance Coordinators</b> are the first point of contact in case you have any questions or need support.
   </li>
   <li>
   The assigned  <b>Risk Manager</b>  reviewing the issue documentation and approving the issue for go live.
   </li>
   </ul>
   When clicking the proceed button, the issue will be saved as draft and the Issue Owner will receive an e-mail notification with a link to this issue
       <br>
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <ul class='m-0'>
       <li>
       Governance Coordinators are not available in all areas of Swiss Re. Please feel free to directly contact the assigned Risk Manager in such cases.
       </li>
   </ul>
     `,
  },
  {
    infoKey: InfoKey.ISSUE_IMPACT_RATING,
    title: 'Impact Rating ',
    information: `
       The Group Risk Impact Assessment of the issue is performed at the time when the issue has been raised. It indicates the potential loss to Swiss Re Group should the impacted risk(s) occur.
       <br>
       <ul class='m-0'>
       <li>
       The assessment is done by the person raising the issue. The highest impact assessment drives the issue rating.
       </li>
   <li>
   The rating is assessed at the point of reporting and not changed during the remediation of the issue.
   </li>
   <li>
   All issues, with exception of issues recorded by GIA are reviewed for completeness and accuracy by the  embedded operational risk manager and approved before the remediation starts.
   </li>
   <li>
   The rating may be challenged by the embedded operational risk manager, before the approve the go live.
   </li>
   </ul>
   <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <ul class='m-0'>
       <li>
       While the RCSA owner is responsible for the initial remediation planning and scheduling as well as for appointing an appropriate issue owner, the issue owner needs to make sure that the issue is appropriately recorded.
       </li>
   <li>
   When it comes to management override of controls, i.e. attempt by management and/or those charged with governance to manipulate accounting records and/or produce fraudulent financial statements by circumventing one or more control activities, the assurance function raising the issue may opt for a higher rating than what impact assessment would suggest due to the fraudulent nature of such behaviors and the poor tone-at-the-top they propagate throughout the organization.
   </li>
   </ul>
     `,
  },
  {
    infoKey: InfoKey.ISSUE_REMEDIATION,
    title: 'Issue remediation ',
    information: `
       The issue owner plans the individual action items, assigns them to action owners and monitors execution thereof.
       <br>
       <ul class='m-0'>
       <li>
       Action items can be added, changed, completed and cancelled at any point in time during the issue remediation.
       </li>
       <li>
       The issue owner runs the remediation of the issue and ensures that agreed actions are completed within their due dates.
       </li>
       <li>
       Once all actions items are completed and the risk is sufficiently mitigated, the issue owner can request the completion of the issue, which can be accepted or rejected by the RCSA owner. Only when the RCSA owner has approved the request, the issue is formally completed
       </li>
       </ul>
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <ul class='m-0'>
       <li>
       In case an issue cannot be completed by the agreed due date, the issue owner can request a due date postponement. Such an extension must be approved by the RCSA owner before the agreed target date is reached.
       </li>
       <li>
       The assigned embedded operational risk manager monitors the issue management performance and challenges the RCSA owner periodically.
       </li>
       </ul>
      `,
  },
  {
    infoKey: InfoKey.ISSUE_BUSINESS_IMPACT,
    title: 'Business Impact',
    information: `
       It is important for us to understand whether an issue has an impact on our ability to do business.
       <br>
       “No Business Impacts” indicates that the reported issue is impacting the Group Functions of Swiss Re only
       <br>
       In case the issue impacts one or several of our Business Units, select all the divisions and areas impacted.
       <br>
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <ul class='m-0'>
       <li>
       This information may help you to select the correct legal entities in the next step.
       </li>
   <li>
   You are allowed to skip this step, the information will become mandatory to complete the reporting.
   </li>
   </ul>
     `,
  },
  {
    infoKey: InfoKey.ISSUE_LEGAL_ENTITIES,
    title: 'Legal Entities',
    information: `
       It is important for us to understand which of the Swiss Re legal entities are impacted by this issue.
       <br>
       One ORM provides you with a system proposed selection based on the Legal Entities in scope of your RCSA including
       <br>
       <ul class='m-0'>
       <li>
       Legal entities in scope of your <b>RCSA</b>; and
       </li>
   <li>
   Legal entities derived from your <b>business unit impacted</b>  selection.
   </li>
   </ul>
   <br>
   Additionally you can do a
   <br>
   <ul class='m-0'>
   <li>
   free-text search,
   </li>
   <li>
   find any legal entities by their jurisdiction (<b>region</b> or <b>country</b>); or
   </li>
   <li>
   filter by ownership (<b>management responsible</b>)
   </li>
   </ul>
   <div class='mt-3'>
   <b>
   Additional Information:
   </b>
   </div>
   <ul class='m-0'>
   <li>
   You are allowed to skip this step, the information will become mandatory to complete the reporting.
   </li>
   </ul>
     `,
  },
  {
    infoKey: InfoKey.ISSUE_OUTSOURCING,
    title: 'Outsourcing',
    information: `
       Indicates whether the operational event or issue was caused by an external provider or a team from different location/legal entity.<br>
       <ul class='m-0'>
        <li>External outsourcing - caused by an external service provider.</li>
        <li>Internal outsourcing - caused by a team providing services to different location/legal entity (BSC Bangalore, BSC Bratislava, Other).</li>
        <li>No outsourcing - not caused by an external or internal service provider.</li>
       </ul>
       <div class='mt-3'>
        <b>
        Additional Information:
        </b>
       </div>
       <ul class='m-0'>
        <li>You are allowed to skip this step, the information will become mandatory to complete the reporting.</li>
       </ul>
      `,
  },
  {
    infoKey: InfoKey.ISSUE_ICOFR,
    title: 'ICOFR',
    information: `
        Indicates whether this operational issue is related to US GAAP or EVM or IFRS.
        <br>
        This field is read only, calculated based on the following logic:
        <br>
        <ul class='m-0'>
          <li> If the case is created on risk R1XXX, then the case is 'USGAAP & EVM & IFRS', except:
            <ul>
              <li>If the case is created on risk R1119, R1125 or R1129, then the case is 'EVM' or</li>
              <li>If the case is created on risk R1130, R1131, R1132 or R1133, then the case is 'IFRS'.</li>
            </ul>
          </li>
          <li> If the case is created on risk R7072, R7073, R7075, R7076 or R7077, then the case is 'USGAAP & EVM & IFRS'.</li>
          <li> If the case is created on any other risk, then the case is 'Not ICoFR'.</li>
          <li> If the case is created on a control, then the ICoFR value is inherited from the control.</li>
          <li> If the case is linked to multiple risks or controls, then the case assumes all their ICoFR values.</li>
        </ul> `,
  },
  {
    infoKey: InfoKey.ISSUE_COMPLIANCE,
    title: 'Compliance and Legal Risk Category',
    information: `
       Compliance and Legal Risk identification is done by Compliance when new Operational Events and Issues have been endorsed by Risk Management.
       <b class='ml-1'> (Note: Legal Risk in this context only refers to Fair Competition and Licensing risks.)</b>
       <br>
       <ul class='m-0'>
       <li>
       <b> Bribery and Corruption:</b> Risk of offering or receiving any advantage that influences a person to perform a duty improperly or misuse power for a personal benefit.
     </li>
     <li>
     <b> Conduct Risk:</b> Risk of any activity that may result in unfair outcomes or treatment of retail customers.
     </li>
     <li>    <b> Conflict of Interest:</b> Risk that a personal or financial interest, outside activity, or relationship either influences, or appears to or influences, a Swiss Re business decision.
     </li>
     <li>   <b>  Data Protection:</b> Risk of failing to protect personal data or respect privacy rights of employees, customers, clients, partners or other relevant individuals.
     </li>
     <li>    <b> Fair Competition:</b> Risk of involvement in any activity that prevents, restricts, or may improperly impact business competition.
     </li>
     <li>    <b> Fraud:</b> Risk of activities carried out by employees or others against Swiss Re with intentional deception intended to result in any personal gain.
     </li>
     <li>    <b> Insider Trading:</b> Risk of the use of Swiss Re's Inside Information for a person's own benefit or for the benefit of a third party.
     </li>
     <li>     <b>International Trade Controls:</b> Risk of doing business that violates sanctions or trade controls that prohibit activity with those entities, persons, sectors or countries.
     </li>
     <li>    <b> Investment Compliance:</b> Risk of conducting financial markets activities that do not comply with internal and external requirements that regulate financial markets.
     </li>
     <li>    <b> Licensing:</b> Risk of conducting business activities without having the appropriate authorization.
     </li>
     <li>     <b>Money Laundering and Terrorist Financing:</b> Risk of introducing proceeds into the financial system that are obtained from illegal sources or intended for terrorist financing.
     </li>
     </ul>
     `,
  },
  {
    infoKey: InfoKey.ISSUE_WORKFLOW_GUIDANCE,
    title: 'Issue Workflow Guidance',
    information: `
       The workflow in OneORM is aligned with Swiss Re's Operational Risk Management methodology.
       <br>
       <br>
       While One ORM allows Governance to actively support the first line risk takers in reporting operational events and raising issues there are a few restrictions due to the responsibilities.
       <br>
       <br>
       As Issue owner and delegate are responsible to resolve the issue by the deadline. Please Note: All deadlines are CET (Central European Time).
       <br>
       <br>
       <b>
       -> Only they can request the go live of an issue, as well as reschedules and completion approval.
       </b>
       <br>
       <br>
       As the RCSA Owners are ultimately accountable that problems are timely resolved
       <br>
       <br>
       <b>
       -> Only they can approve issue reschedule and completion requests.
       </b>
      `,
  },
  {
    infoKey: InfoKey.ISSUE_ATTACHMENTS,
    title: 'Attachments',
    information: `
      Attaching documents to issues is optional (e.g. evidence supporting the issue resolution progress).
      <ul class='m-0'>
      <li>
      Attachments do not make up for an incomplete documentation of the issue in OneORM. Specifically "issue description", "resolution approach" and "latest assessment" must be comprehensively documented in the respective fields.
      </li>
      <li>
      When attaching documents, make sure that they do not contain any sensitive or personal information.             </li>
     </ul>
     <br>
     <br>
     Attachments can be added and removed until the issue is completed. Should you need to add any additional documents for completed issues, approach your Governance Coordinator or Op Risk Manager for support.
     `,
  },
  {
    infoKey: InfoKey.ISSUE_SUBSCRIBED_USERS,
    title: 'Subscribed Users',
    information: `
            Users listed in this field are granted read access to the related risk case and are automatically cc's in all email notifications.
            Risk cases belonging to an RCSA to which a user has been subscribed become visible in the "ALL CASES" section on the OneORM home page.
            <br>
            <br>
            To subscribe to any RCSA, please reach out to your Op Risk Manager or Governance team.
            `,
  },
];
export const informationLists = [
  {
    infoKey: InfoKey.EVENT_MANDATE,
    title: 'Mandate',
    information: `We assign all reported cases (Issues and Events) in Risk and Control Assessments (RCSA) to clearly define ownership to an organizational entity.
       <br>
       <span class="material-icons">
  arrow_right
  </span>
  Please confirm the Organisational Unit & RCSA where your case best belongs to or follow the wizard to choose a different one.
       <br>
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <div class='mt-3'>
       <b>
       What is an RCSA?
       </b>
       </div>
       <ul class='m-0'>
   <li>The Risk and Control Self-Assessment (also known as RCSA) provides an overview of all identified material operational risk exposures for a specific organisational unit, including all open issues and events.
   </li>
   <li>
   RCSA Owners periodically (re-) assess control effectiveness against identified material operational risk exposures.
  </li>
  </ul>
       `,
  },
  {
    infoKey: InfoKey.EVENT_ISSUE,
    title: 'Event or Issue',
    information: `We differentiate between things that have happened in order to learn from them and foster transparency; and things which need to be addressed to prevent them from happening in the future.
       <br>
       <span class="material-icons">
  arrow_right
  </span>
       Please select whether something <b>“has happened” (Event)</b> or describes a problem which
       <b>“needs to be addressed” (Issue)</b>.
       <br>
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <div class='mt-3'>
       <b>
       What is an Event?
       </b>
       </div>
       <ul class='m-0'>
       <li>
       An operational event is a risk which has materialised, I.e., something went wrong.
       </li>
   <li>
   Ongoing efforts after the initial reporting are primarily related to root cause analysis and the documentation of lessons learned.
   </li>
   <li>
   Should any corrective actions be identified to prevent a re-occurring they can also be raised as issues at a later stage and should not delay the initial reporting of the event.
   </li>
  </ul>
  <div class='mt-3'>
  <b>
  What is an Issue?
  </b>
  </div>
  <ul class='m-0'>
  <li>
  An issue describes a problem where a process or a control is non-existent, is not working as designed or where there is an opportunity to strengthen an existing control.
  </li>
  <li>
  Corrective action plans are required to fix the issue within a timely manner in order to avoid a risk materializing/move outside the tolerance limits for Swiss Re.
  </li>
  </ul>
       `,
  },
  {
    infoKey: InfoKey.EVENT_DESCRIPTION,
    title: 'Description',
    information: `
       This is the place to provide an initial description of what has happened. The dates can be changed until the event as long as it is in status “draft”. Title and Detail Description can be updated any time throughout the entire lifecycle of the event until closed.
       <br>
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <div class='mt-3'>
       <b>
       What makes a good event title?
       </b>
       </div>
       <ul class='m-0'>
       <li>
       A good op event title can serve as a stand-alone title in a report or dashboard. It should be a meaningful short version of "what happened" or "what went wrong".
       </li>
   <li>
   Avoid wordy titles, not generic abbreviations, codes, dates, locations, monetary amounts and references to policies, risks or controls.
   </li>
   <li>
   Critical information belongs into the detailed description field.
   </li>
  </ul>
  <div class='mt-3'>
  <b>
  What makes a good detailed description?
  </b>
  </div>
  <ul class='m-0'>
  <li>
  We need to differentiate the detail description at the time of initial reporting and at the time when the event is closed.
  </li>
  <li>
  Initial reporting:
  <br>
  <span class="material-icons">
  arrow_right
  </span>
  An initial documentation of the known risk relevant aspects of the event including a description of what has happened to understand why this event qualifies for reporting is sufficient.
  </li>
  <li>
  Event Closure:
  <br>
  <span class="material-icons">
  arrow_right
  </span>
  The detailed description should be updated to contain all risk relevant aspects of the event including a description of what has happened, its impact and how it was discovered.
  </li>
  <li>
  <b>
  Check Text Quality:
  </b>
   is a new experimental feature to help you in various aspects of writing the event description. Starting small with limited functionality, we will improve it over time. Its use is optional and does not replace the event owner's responsibility for writing an accurate event description.
  </li>
  </ul>
       `,
  },
  {
    infoKey: InfoKey.EVENT_RISK,
    title: 'Risk',
    information: `
       In order to manage our operational risks effectively and observe Group wide trends we map all events to their corresponding operational risks.
       <br>
       Operational Events are materialized risks. One ORM provides you with the ones which are actively managed within your selected RCSA.
       <br>
        <span class="material-icons">
       arrow_right
       </span>
       Choose the risk which best matches the reported event.
       <br>
        <span class="material-icons">
       arrow_right
       </span>
       Should no risk fit your case you can proceed without Risk Selection. In such a case One ORM automatically assigns the event directly to the RCSA.
       <br>
       <span class="material-icons">
      arrow_right
      </span>
      In case you realise that you have chosen the wrong risk, you can still change it later.
      <br>
      <span class="material-icons">
     arrow_right
     </span>
     Please do not hesitate to reach out to  your governance coordinators and ORM experts for help.
     <br>
     <div class='mt-3'>
     <b>
     Additional Information:
     </b>
     </div>
     <div class='mt-3'>
     <b>
     What is an operational risk?
     </b>
     </div>
     <ul class='m-0'>
     <li>
     Operational risk represents a potential economic loss, a financial reporting misstatement, a reputational or a regulatory compliance impact resulting from inadequate or failed internal processes, people and systems, or from external causes.
     </li>
  <li>
  Operational risk includes legal and compliance risks and the risk of a material misstatement in Swiss Re's financial reporting, but excludes strategic and business risks such as insurance, financial market or credit risk, which Swiss Re deliberately underwrites or invests in as part of its core business activities.
  </li>
  </ul>
       `,
  },
  {
    infoKey: InfoKey.EVENT_ROLES,
    title: 'Roles',
    information: `
       There are several roles involved in the event reporting process.
       <ul class='m-0'>
       <li>
       The  <b> Event Reporter  </b> (you) is the person who initially reports a new event in One ORM.
       </li>
  <li>
       The <b> Event Owner  </b> is the person taking over from you to updating any further developments, driving the root cause analysis and documentation of the lessons learned.
       </li>
  <li>
       <b> Governance Coordinators  </b> are the first point of contact in case you have any questions or need support.
       </li>
       <li>
  The assigned  <b>  Risk Manager </b> reviewing the issue documentation and approving the issue for go live.
  </li>
  </ul>
       When clicking the proceed button, the event will be saved as draft and the <b> Event Owner  </b> will receive an e-mail notification with a link to this event.
       <br>
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <ul class='m-0'>
       <li>
       <b> Governance Coordinators  </b> are not available in all areas of Swiss Re. Please feel free to directly contact the assigned Risk Manager in such cases.
  </li>
  </ul>
     `,
  },
  {
    infoKey: InfoKey.EVENT_BUSINESS_IMPACT,
    title: 'Business Impact',
    information: `
       It is important for us to understand whether an event has an impact on our ability to do business.
       <br>
       “No Business Impacts” indicates that the reported event is impacting the Group Functions of Swiss Re only
       <br>
       In case the event impacts one or several of our Business Units, select all the divisions and areas impacted.
       <br>
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <ul class='m-0'>
       <li>
       This information may help you to select the correct legal entities in the next step.
       </li>
   <li>
   You are allowed to skip this step, the information will become mandatory to complete the reporting.
   </li>
   </ul>
     `,
  },
  {
    infoKey: InfoKey.EVENT_LEGAL_ENTITIES,
    title: 'Legal Entities',
    information: `
       It is important for us to understand which of the Swiss Re legal entities are impacted by this event.
       <br>
       One ORM provides you with a system proposed selection based on the Legal Entities in scope of your RCSA including
       <br>
       <ul class='m-0'>
       <li>
       Legal entities in scope of your <b>RCSA</b>; and
       </li>
   <li>
   Legal entities derived from your <b>business unit impacted</b>  selection.
   </li>
   </ul>
   <br>
   Additionally you can do a
   <br>
   <ul class='m-0'>
   <li>
   free-text search,
   </li>
  <li>
  find any legal entities by their jurisdiction (<b>region</b> or <b>country</b>); or
  </li>
  <li>
  filter by ownership (<b>management responsible</b>)
  </li>
  </ul>
  <div class='mt-3'>
  <b>
  Additional Information:
  </b>
  </div>
  <ul class='m-0'>
  <li>
  You are allowed to skip this step, the information will become mandatory to complete the reporting.
  </li>
  </ul>
     `,
  },
  {
    infoKey: InfoKey.EVENT_OUTSOURCING,
    title: 'Outsourcing',
    information: `
       Indicates whether the operational event or issue was caused by an external provider or a team from different location/legal entity.<br>
       <ul class='m-0'>
        <li>External outsourcing - caused by an external service provider.</li>
        <li>Internal outsourcing - caused by a team providing services to different location/legal entity (BSC Bangalore, BSC Bratislava, Other).</li>
        <li>No outsourcing - not caused by an external or internal service provider.</li>
       </ul>
       <div class='mt-3'>
        <b>
        Additional Information:
        </b>
       </div>
       <ul class='m-0'>
        <li>You are allowed to skip this step, the information will become mandatory to complete the reporting.</li>
       </ul>
      `,
  },
  {
    infoKey: InfoKey.EVENT_ICOFR,
    title: 'ICOFR',
    information: `
        Indicates whether this operational event is related to US GAAP or EVM or IFRS.
        <br>
        This field is read only, calculated based on the following logic:
        <br>
        <ul class='m-0'>
          <li> If the case is created on risk R1XXX, then the case is 'USGAAP & EVM & IFRS', except:
            <ul>
              <li>If the case is created on risk R1119, R1125 or R1129, then the case is 'EVM' or</li>
              <li>If the case is created on risk R1130, R1131, R1132 or R1133, then the case is 'IFRS'.</li>
            </ul>
          </li>
          <li> If the case is created on risk R7072, R7073, R7075, R7076 or R7077, then the case is 'USGAAP & EVM & IFRS'.</li>
          <li> If the case is created on any other risk, then the case is 'Not ICoFR'.</li>
          <li> If the case is created on a control, then the ICoFR value is inherited from the control.</li>
          <li> If the case is linked to multiple risks or controls, then the case assumes all their ICoFR values.</li>
        </ul> `,
  },
  {
    infoKey: InfoKey.EVENT_MONETARY_IMPACT,
    title: 'Monetary Impact',
    information: `
       Some Operational Events result in a monetary impact on Swiss Re’s financials. Such monetary impacts need to be reported when they have occurred. In case that there is no monetary impact (yet), you can skip this step. 
       <br>
       We distinguish among three types of monetary impacts:
       <br>
       <ul class='m-0'>
       <li>
       <b> Cash-out:</b> Money lost due to an operational event. In other terms this means “cash-out the door” and excludes cost and loss estimates as well as opportunity costs. In case of the latter, please report them in the Detail Description of this event.
       </li>
       <li>
       <b> Operational gains:</b> A positive impact of the event. E.g. Improper underwriting and trading activities that don't follow agreed guidelines and practices. An event can therefore result in an ultimate gain. 
       </li>
       <li>
       <b>  Accounting adjustments:</b> An event can result in the necessity to subsequently shift capital: We might have to increase (or decrease) our reserves or we might need to correct an error within accounting (financial misstatement). 
       </li>
       </ul>
       <br>
       Select the item that best suits your reported monetary impact. For Financial adjustments and Operational gains you can report one instance per event which can be amended later on in the process.
       <div class='mt-3'>
       <b>
       Additional Information:
       </b>
       </div>
       <ul class='m-0'>
       <li>
       Opportunity costs do not need to be captured here. In case you want to report them, please include them in the detail description after completing the initial reporting.
       </li>
       <li>
       While Accounting adjustments can be a rough estimate, we expect you report factual Cash-Outs and Operational gains.
       </li>
       </ul>
     `,
  },
  {
    infoKey: InfoKey.EVENT_BUSINESS_FUNCTION,
    title: 'Function Code (Business Function)',
    information: `
       <br>
       Represents the company function in which the loss originated.
       `,
  },
  {
    infoKey: InfoKey.EVENT_COUNTRY,
    title: 'Event Location (Country)',
    information: `
        <br>
        Country where the loss occurred (e.g. where the P&L is).
        `,
  },
  {
    infoKey: InfoKey.EVENT_ROOT_CAUSE,
    title: 'Root Cause',
    information: ` This is the initiating cause of (what gave rise to) the event.
          <div class='mt-3'>
          <b>
          Additional Information:
          </b>
          </div>
          <br>
          You can change this information later.
          <br>
          Please find any further information describing the individual Root Cause Codes  <a href="https://swissre.sharepoint.com/:w:/r/sites/team-GRACE/_layouts/15/Doc.aspx?sourcedoc=%7B96FEE77F-F384-4C71-BED9-A4B50D7EB305%7D&file=ORM%20-%20Root%20Cause%20Documentation.docx&wdLOR=cAA1487CF-365F-48CF-A11A-FB6548DB1AB0&action=default&mobileredirect=true" target="_blank" rel="noopener noreferrer">here</a>.
          `,
  },
  {
    infoKey: InfoKey.EVENT_TYPE,
    title: 'Event Type',
    information: ` Event Types represent a description of what happened.
           <br>
           Event Type identification is done by Risk Management before endorsing a new Operational Event.
           <div class='mt-3'>
           <b>
           Event Type level 1
           </b>
           </div>
           Represents the 7 Basel 2 level 1 event type categories set by the Bank for International Settlements (BIS).
           <div class='mt-3'>
           <b>
           Event Type Level 2
           </b>
           </div>
           Represents the Basel 2 level 2 event type categories adjusted to insurance business.
           <br>
           <br>
           <br>
           Please find any further information describing the individual Event Types  <a href="https://swissre.sharepoint.com/:w:/r/sites/team-GRACE/_layouts/15/Doc.aspx?sourcedoc=%7BD4BA56CA-38CC-41C6-BFE2-5A506BC92F39%7D&file=ORM%20-%20Event%20Type%20Documentation.docx&wdLOR=c2BA3A137-E67A-403E-A0D3-9793E87B6BB2&action=default&mobileredirect=true" target="_blank" rel="noopener noreferrer">here</a>
           .
           `,
  },
  {
    infoKey: InfoKey.EVENT_COMPLIANCE,
    title: 'Compliance and Legal Risk Category',
    information: `
             Compliance and Legal Risk identification is done by Compliance when new Operational Events and Issues have been endorsed by Risk Management.
             <b class='ml-1'> (Note: Legal Risk in this context only refers to Fair Competition and Licensing risks.)</b>
             <br>
             <ul class='m-0'>
             <li>
             <b> Bribery and Corruption:  </b> Risk of offering or receiving any advantage that influences a person to perform a duty improperly or misuse power for a personal benefit.
           </li>
           <li>
           <b> Conduct Risk:</b> Risk of any activity that may result in unfair outcomes or treatment of retail customers.
           </li>
           <li>    <b> Conflict of Interest:</b> Risk that a personal or financial interest, outside activity, or relationship either influences, or appears to or influences, a Swiss Re business decision.
           </li>
           <li>   <b>  Data Protection:</b> Risk of failing to protect personal data or respect privacy rights of employees, customers, clients, partners or other relevant individuals.
           </li>
           <li>    <b> Fair Competition:</b> Risk of involvement in any activity that prevents, restricts, or may improperly impact business competition.
           </li>
           <li>   <b>  Fraud:</b> Risk of activities carried out by employees or others against Swiss Re with intentional deception intended to result in any personal gain.
           </li>
           <li>    <b> Insider Trading:</b> Risk of the use of Swiss Re's Inside Information for a person's own benefit or for the benefit of a third party.
           </li>
           <li>    <b> International Trade Controls:</b> Risk of doing business that violates sanctions or trade controls that prohibit activity with those entities, persons, sectors or countries.
           </li>
           <li>    <b> Investment Compliance:</b> Risk of conducting financial markets activities that do not comply with internal and external requirements that regulate financial markets.
           </li>
           <li>    <b> Licensing:</b> Risk of conducting business activities without having the appropriate authorization.
           </li>
           <li>    <b> Money Laundering and Terrorist Financing:</b> Risk of introducing proceeds into the financial system that are obtained from illegal sources or intended for terrorist financing.
           </li>
           </ul>
           `,
  },
  {
    infoKey: InfoKey.EVENT_WORKFLOW_GUIDANCE,
    title: 'Event Workflow Guidance',
    information: `
             The workflow in OneORM is aligned with Swiss Re's Operational Risk Management methodology.
             <br>
             <br>
             While One ORM allows Governance to actively support the first line risk takers in reporting operational events and raising issues there are a few restrictions due to the responsibilities.
             <br>       <br>
             As Op Event owner and delegate are responsible for root cause analysis documentation and doing lessons learned
             <br>         <br>
             <b>
             -> Only they can request closure approval.
             </b>
            `,
  },
  {
    infoKey: InfoKey.EVENT_ATTACHMENTS,
    title: 'Attachments',
    information: `
            Attaching documents to events is optional (e.g. documents supporting the root cause analysis).
            <ul class='m-0'>
            <li>
            Attachments do not make up for an incomplete documentation of the event in OneORM. Specifically the "event description", "root cause analysis", "lessons learned and actions taken" must be comprehensively documented in the respective fields.          </li>
            <li>
            When attaching documents, make sure that they do not contain any sensitive or personal information.                 </li>
           </ul>
           <br>
           <br>
           Attachments can be added and removed until the event is closed. Should you need to add any additional documents for closed events, approach your Governance Coordinator or Op Risk Manager for support.
           `,
  },
  {
    infoKey: InfoKey.EVENT_SUBSCRIBED_USERS,
    title: 'Subscribed Users',
    information: `
            Users listed in this field are granted read access to the related risk case and are automatically cc's in all email notifications.
            Risk cases belonging to an RCSA to which a user has been subscribed become visible in the "ALL CASES" section on the OneORM home page.
            <br>
            <br>
            To subscribe to any RCSA, please reach out to your Op Risk Manager or Governance team.
            `,
  },
];
