import { OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, effect, inject, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { OverlaySpinnerService } from '@shared/components/overlay-spinner/overlay-spinner.service';

@Component({
  selector: 'app-overlay-spinner',
  template: `
    <ng-template #progressSpinner>
      <mat-progress-spinner [diameter]="diameter" [strokeWidth]="strokeWidth" color="primary" mode="indeterminate"> </mat-progress-spinner>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatProgressSpinner],
})
export class OverlaySpinnerComponent implements OnInit {
  @ViewChild('progressSpinner')
  private readonly progressSpinner: TemplateRef<MatProgressSpinner>;

  readonly diameter = 64;
  readonly strokeWidth = 2;

  private overlayRef: OverlayRef;
  private readonly vcRef = inject(ViewContainerRef);
  private readonly overlaySpinnerService = inject(OverlaySpinnerService);

  constructor() {
    effect(() => {
      if (this.overlaySpinnerService.loading() && !this.overlayRef.hasAttached()) {
        this.overlaySpinnerService.attachTemplatePortal(this.overlayRef, this.progressSpinner, this.vcRef);
      } else if (!this.overlaySpinnerService.loading() && this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
      }
    });
  }

  ngOnInit(): void {
    this.overlayRef = this.overlaySpinnerService.createOverlay();
  }
}
