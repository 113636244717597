import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShowMoreDirective } from '@shared/directives/show-more.directive';
import { RcsaControlRiskCase, RcsaRC } from 'oneorm-api-http-client';

import { ValueOf } from '@shared/utils';

type Data = RcsaRC & RcsaControlRiskCase;

interface ListData {
  list: Data[];
  nameShow: keyof Data;
}

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  imports: [ShowMoreDirective, NgForOf, NgClass, NgIf],
  standalone: true,
})
export class ReadMoreComponent {
  @Input() text: string;
  @Input() listData: ListData;
  @Input() clickedItem: boolean;
  @Input() tableRowNumber: number;
  @Input() noOfLines: number = 3;
  @Input() hideToggleFullText: boolean = false;
  @Output() listItemOutput = new EventEmitter<Data>();
  @Output() toggleFullText = new EventEmitter<boolean>();
  @Output() textClickEmit = new EventEmitter<boolean>();

  hideToggle: boolean = true;

  itemClick(itemData: Data): void {
    if (this.clickedItem) {
      this.listItemOutput.emit(itemData);
      return;
    }
    this.textClickEmit.emit(this.hideToggleFullText);
  }

  showMoreWork(showMoreValue: boolean): void {
    this.hideToggle = showMoreValue;
  }

  showMoreToggelButton(): void {
    this.hideToggleFullText = !this.hideToggleFullText;
    this.toggleFullText.emit(this.hideToggleFullText);
  }

  showItem(item: Data, attr: keyof Data): ValueOf<Data> {
    return item[attr];
  }

  textClick(): void {
    this.textClickEmit.emit(this.hideToggleFullText);
  }
}
