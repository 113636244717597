import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

const isFormGroup = (control: AbstractControl): control is FormGroup => control instanceof FormGroup;

export const allOrNoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control || !isFormGroup(control)) {
    return null;
  }

  const controls = control.controls;
  const values = Object.keys(controls).map(key => controls[key].value);
  const allEmpty = values.every(value => !value);
  const allFilled = values.every(value => !!value);

  if (allEmpty || allFilled) {
    return null;
  }

  return { allOrNone: true };
};
