import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

import { environment } from '../../../environments/environment';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.clientId,
      authority: environment.msalConfig.authority,
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
  });
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, [environment.msalConfig.scope]);
  protectedResourceMap.set('https://graph.microsoft.com/', ['User.Read', 'profile', 'openid', 'offline_access']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.msalConfig.scope],
    },
  };
}
