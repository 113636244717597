import { Pipe, PipeTransform } from '@angular/core';
import { ValueGetterFunc } from '@styles/p-table/model/col-def.model';

import { isFunction } from '@shared/utils';

@Pipe({
  standalone: true,
  name: 'isFunction',
})
export class IsFunctionPipe implements PipeTransform {
  transform(value: string | ValueGetterFunc<unknown>): boolean {
    return isFunction(value);
  }
}
