<button class="iconBtn" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" mat-fab>
  <mat-icon>settings</mat-icon>
</button>

<mat-menu class="manageMenu" #menu="matMenu">
  <ul class="hambrgurMenuBody dropdown-menu dropdown-menu-right">
    <div class="menuTitleContainer">
      <span class="material-icons menuIcon" dropdownToggle> close </span>
    </div>
    <div class="menuTitle mt-2">Action</div>

    <li role="menuitem">
      <a class="dropdown-item" (click)="newCase()" dropdownToggle>Report a new case (event or issue)</a>
    </li>
    <li role="menuitem">
      <a class="dropdown-item" (click)="goOutsideApp(requestControlChange)" dropdownToggle>Request a control change</a>
    </li>
    <li role="menuitem">
      <a
        class="dropdown-item"
        (click)="goOutsideApp('https://swissreesm.service-now.com/contactone?id=request_access_rights')"
        dropdownToggle
        >Request access</a
      >
    </li>
    <li role="menuitem">
      <a
        class="dropdown-item"
        (click)="goOutsideApp('https://swissreesm.service-now.com/contactone?id=request_support_view')"
        dropdownToggle
        >Report bug</a
      >
    </li>

    <li class="divider dropdown-divider"></li>

    <div class="menuTitle mt-2">Dashboards and reports</div>

    <li role="menuitem">
      <a class="dropdown-item" (click)="goOutsideApp(environmentVariables.OperationalRiskDashboard)" dropdownToggle
        >Operational Risk Dashboards</a
      >
    </li>
  </ul>
</mat-menu>

<button class="iconBtn" [matMenuTriggerFor]="help" aria-label="Example icon-button with a menu" mat-fab>
  <mat-icon>help_outline</mat-icon>
</button>
<mat-menu class="manageMenu" #help="matMenu">
  <ul class="hambrgurMenuBody dropdown-menu dropdown-menu-right" id="dropdown-alignment">
    <div class="menuTitleContainer">
      <span class="material-icons menuIcon" dropdownToggle> close </span>
    </div>
    <div class="menuTitle mt-2">What's new</div>
    <li role="menuitem">
      <a class="dropdown-item" (click)="goOutsideApp('https://wiki.swissre.com/pages/viewpage.action?pageId=396202449')" dropdownToggle
        >OneORM - Release log</a
      >
    </li>

    <li class="divider dropdown-divider"></li>

    <div class="menuTitle mt-2">OneORM - Help</div>
    <li role="menuitem">
      <a class="dropdown-item" (click)="onFaqLinkClick()" dropdownToggle>Frequently asked questions (FAQ)</a>
    </li>
    <li role="menuitem">
      <a class="dropdown-item" (click)="infoDialog.setInfoData('RCSA')" dropdownToggle>RCSA</a>
    </li>
    <li role="menuitem">
      <a class="dropdown-item" (click)="infoDialog.setInfoData('Event')" dropdownToggle>Event reporting</a>
    </li>
    <li role="menuitem">
      <a class="dropdown-item" (click)="infoDialog.setInfoData('Issue')" dropdownToggle>Issue management</a>
    </li>

    <li class="divider dropdown-divider"></li>

    <div class="menuTitle mt-2">ORM - Standards</div>

    <li role="menuitem">
      <a
        class="dropdown-item"
        (click)="goOutsideApp('https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000650.pdf')"
        dropdownToggle
        >RCSA</a
      >
    </li>
    <li role="menuitem">
      <a
        class="dropdown-item"
        (click)="goOutsideApp('https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000647.pdf')"
        dropdownToggle
        >Event reporting</a
      >
    </li>
    <li role="menuitem">
      <a
        class="dropdown-item"
        (click)="goOutsideApp('https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000646.pdf')"
        dropdownToggle
        >Issue management</a
      >
    </li>
  </ul>
</mat-menu>

<div style="display: none">
  <app-user-guide #infoDialog></app-user-guide>
</div>
