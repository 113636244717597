import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-menu',
  templateUrl: './manage-menu.component.html',
  styleUrls: ['./manage-menu.component.scss'],
})
export class ManageMenuComponent {
  requestControlChange = environment.requestControlChange;
  environmentVariables = environment;

  goOutsideApp(url: string): void {
    window.open(url, '_blank');
  }

  newCase(): void {
    const url = environment.oneOrmUrl + '#/new-risk/mandate';
    window.open(url, '_self');
  }

  onFaqLinkClick(): void {
    window.open(environment.faqUrl, '_blank');
  }
}
