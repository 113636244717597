<app-top-navbar></app-top-navbar>
<mat-drawer-container [hasBackdrop]="false" *ngIf="!isMobile">
  <mat-drawer [mode]="drawerMode$ | async" [opened]="drawerOpened$ | async" [position]="'end'">
    <app-right-sidenav></app-right-sidenav>
  </mat-drawer>
  <mat-drawer-content>
    <app-overlay-spinner></app-overlay-spinner>
    @if (!isIframe) {
      <router-outlet></router-outlet>
    }
  </mat-drawer-content>
</mat-drawer-container>
<mat-drawer-container *ngIf="isMobile">
  <mat-drawer-content>
    <div class="d-flex justify-content-center align-items-center loading">
      <div class="SystemNotice">
        <img height="30" width="30" ngSrc="assets/images/rcsa/ic3-red.svg" alt="Info" /><br />
        <strong> Important: </strong> OneORM is not accessible on mobile devices, please use Swiss Re notebook instead.
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
