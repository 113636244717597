import { LayoutModule } from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { inject, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { RightSidenavStore } from '@core/right-sidenav/right-sidenav.store';
import { IconService } from '@shared/services/icon.service';

import { UserGuideComponent, VersionComponent } from '@shared/components';
import { SkeletonLoaderDirective } from '@shared/directives';
import { IsFalsePipe, IsNotEmptyPipe } from '@shared/pipe';
import { PopoverService, ToastService } from '@shared/services';

import { BreadcrumbComponent } from './top-navbar/breadcrumb/breadcrumb.component';
import { ManageMenuComponent } from './top-navbar/manage-menu/manage-menu.component';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';

@NgModule({
  declarations: [TopNavbarComponent, ManageMenuComponent, BreadcrumbComponent],
  imports: [
    CommonModule,
    RouterModule,
    UserGuideComponent,
    LayoutModule,
    IsFalsePipe,
    SkeletonLoaderDirective,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatToolbarModule,
    MatSnackBarModule,
    IsNotEmptyPipe,
    VersionComponent,
  ],
  exports: [TopNavbarComponent, BreadcrumbComponent, ManageMenuComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
        floatLabel: 'always',
      } as MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        color: 'primary',
      } as MatCheckboxDefaultOptions,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
      } as MatSnackBarConfig,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: {
        hideSingleSelectionIndicator: true,
      },
    },
    IconService,
    PopoverService,
    ToastService,
    RightSidenavStore,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }

    inject(IconService).registerCustomIcons();
  }
}
