<div class="breadcrumb-style small-screen my-0">
  <span class="home material-icons-outlined"> home </span>
  <div class="ml-1" *ngFor="let breadcrumb of breadcrumbs; let i = index">
    <span
      class="material-icons mr-1"
      *ngIf="i > 0 && breadcrumbs.length > 1"
    >
      chevron_right
    </span>
    <a (click)="goOut(breadcrumb)" >
      {{ breadcrumb.label }}
    </a>
  </div>
</div>
