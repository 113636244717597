import { RcsaEventView, RcsaIssueView } from 'oneorm-api-http-client';

import { RcsaViews } from '@shared/models';
import { isDateAfterDays } from '@shared/utils';

const isRcsaIssueView = (row: RcsaViews): row is RcsaIssueView => !Object.keys(row).includes('event_name');

const isRcsaEventView = (row: RcsaViews): row is RcsaEventView => Object.keys(row).includes('event_name');

const DRAFT_STATUS = 'Draft' as const;

export const isIssueOrEventInDraftForDays = (row: RcsaViews): boolean => {
  if (isRcsaIssueView(row)) {
    return row.status.includes(DRAFT_STATUS) && row.draftCreationDate && isDateAfterDays(row.draftCreationDate, 30);
  }

  if (isRcsaEventView(row)) {
    return row.status_name.includes(DRAFT_STATUS) && row.draft_creation_date && isDateAfterDays(row.draft_creation_date, 30);
  }

  return false;
};
