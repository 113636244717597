import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'fieldToColumnWidth',
})
export class FieldToColumnWidthPipe<T extends object> implements PipeTransform {
  transform(
    field: keyof T,
    columnWidths: Record<keyof T, { minWidth?: number; maxWidth?: number }>
  ): { minWidth?: number; maxWidth?: number } {
    return columnWidths[field];
  }
}
