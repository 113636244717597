import { Pipe, PipeTransform } from '@angular/core';
import { isTrue } from '@shared/utils/boolean/is-true';

@Pipe({
  standalone: true,
  name: 'isTrue',
})
export class IsTruePipe implements PipeTransform {
  transform(value: unknown): boolean {
    return isTrue(value);
  }
}
