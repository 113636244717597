import { booleanAttribute, ChangeDetectionStrategy, Component, EventEmitter, Input, numberAttribute, Output } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatAccordionTogglePosition,
  MatExpansionPanel,
  MatExpansionPanelContent,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatRadioButton } from '@angular/material/radio';

import { IsFalsePipe, ToStringPipe } from '@shared/pipe';

@Component({
  selector: 'app-expansion-panel',
  template: `
    <mat-expansion-panel
      class="high-density"
      #expansionPanel
      [class.selected]="selected"
      [disabled]="disabled"
      [hideToggle]="hideToggle"
      [togglePosition]="togglePosition"
    >
      <mat-expansion-panel-header (click)="onExpansionPanelClick(expansionPanel, $event)">
        <mat-panel-title>
          @if (multiSelect | isFalse) {
            <mat-radio-button
              [disabled]="disabled"
              [checked]="selected"
              [id]="id | toString"
              [value]="id"
              color="primary"
            ></mat-radio-button>
          } @else {
            <mat-checkbox
              [disabled]="disabled"
              [checked]="selected"
              [id]="id | toString"
              [value]="id | toString"
              color="primary"
            ></mat-checkbox>
          }
          <ng-content select="[expansion-title]"></ng-content>
        </mat-panel-title>
        <mat-panel-description>
          <ng-content select="[expansion-description]"></ng-content>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-content></ng-content>
      </ng-template>
    </mat-expansion-panel>
  `,
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelContent,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatRadioButton,
    IsFalsePipe,
    MatCheckbox,
    ToStringPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent {
  @Input() selected = false;
  @Input() hideToggle = false;
  @Input() multiSelect = false;
  @Input() togglePosition: MatAccordionTogglePosition = 'before';
  @Input({ required: true, transform: numberAttribute }) id: number;
  @Input({ transform: booleanAttribute }) disabled = false;
  @Output() bodyClick = new EventEmitter<number>();

  private readonly expansionIndicatorClass = 'mat-expansion-indicator';

  onExpansionPanelClick(matExpansionPanel: MatExpansionPanel, event: Event): void {
    event.stopPropagation();

    if (this.disabled) {
      return;
    }

    if (!this.isExpansionIndicator(event.target as HTMLDivElement)) {
      matExpansionPanel.close();
      this.bodyClick.emit(this.id);
    }
  }

  private isExpansionIndicator(target: HTMLDivElement): boolean {
    return target.classList && target.classList.contains(this.expansionIndicatorClass);
  }
}
