import { RiskCaseEnumItem } from 'oneorm-api-http-client/model/riskCaseEnumItem';

import { StatusCheckboxFilter, StatusCheckboxFilterValue } from '../../../home/model/status-filter.model';
import { FilterCheckboxGroupModel } from '../../../rcsa/filter/filter-checkbox-group.model';

export const getEventsRiskCaseStatusFilter = (
  draftEnumItem: RiskCaseEnumItem,
  openEnumItem: RiskCaseEnumItem,
  closedEnumItem: RiskCaseEnumItem,
  cancelledEnumItem: RiskCaseEnumItem
): { status: FilterCheckboxGroupModel<string, StatusCheckboxFilter, StatusCheckboxFilterValue> } => ({
  status: new FilterCheckboxGroupModel<string, StatusCheckboxFilter, StatusCheckboxFilterValue>('Status', [
    {
      filterName: StatusCheckboxFilter.draft,
      label: StatusCheckboxFilterValue.draft,
      value: draftEnumItem.id.toString(),
    },
    {
      filterName: StatusCheckboxFilter.open,
      label: StatusCheckboxFilterValue.open,
      value: openEnumItem.id.toString(),
    },
    {
      filterName: StatusCheckboxFilter.closed,
      label: StatusCheckboxFilterValue.closed,
      value: closedEnumItem.id.toString(),
    },
    {
      filterName: StatusCheckboxFilter.cancelled,
      label: StatusCheckboxFilterValue.cancelled,
      value: cancelledEnumItem.id.toString(),
    },
  ]),
});

export const getIssuesRiskCaseStatusFilter = (
  draftEnumItem: RiskCaseEnumItem,
  openEnumItem: RiskCaseEnumItem,
  completedEnumItem: RiskCaseEnumItem,
  cancelledEnumItem: RiskCaseEnumItem
): { status: FilterCheckboxGroupModel<string, StatusCheckboxFilter, StatusCheckboxFilterValue> } => ({
  status: new FilterCheckboxGroupModel<string, StatusCheckboxFilter, StatusCheckboxFilterValue>('Status', [
    {
      filterName: StatusCheckboxFilter.draft,
      label: StatusCheckboxFilterValue.draft,
      value: draftEnumItem.id.toString(),
    },
    {
      filterName: StatusCheckboxFilter.open,
      label: StatusCheckboxFilterValue.open,
      value: openEnumItem.id.toString(),
    },
    {
      filterName: StatusCheckboxFilter.completed,
      label: StatusCheckboxFilterValue.completed,
      value: completedEnumItem.id.toString(),
    },
    {
      filterName: StatusCheckboxFilter.cancelled,
      label: StatusCheckboxFilterValue.cancelled,
      value: cancelledEnumItem.id.toString(),
    },
  ]),
});
