import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

enum CustomIcon {
  'xlsx' = 'xlsx',
}

@Injectable()
export class IconService {
  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry
  ) {}

  registerCustomIcons(): void {
    Object.values(CustomIcon).forEach(icon => {
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`../../assets/images/${icon}.svg`));
    });
  }
}
