import { Adapter } from '@shared/adapters/adapter';
import { RiskCaseEnumItem } from 'oneorm-api-http-client/model/riskCaseEnumItem';

import { EventsCheckboxFilter, EventsTypeCheckboxFilter, EventsTypeCheckboxFilterValue } from '../../home/model/events-filter.model';
import { FilterCheckboxGroupModel } from '../../rcsa/filter/filter-checkbox-group.model';

const ACTUAL_LOSS_MARK = 'Actual Loss';
const INCIDENT_MARK = 'Incident';

export abstract class EventsTypeEnumAdapter extends Adapter<
  Record<EventsCheckboxFilter.type, FilterCheckboxGroupModel>,
  RiskCaseEnumItem[]
> {
  static fromDto(dto: RiskCaseEnumItem[]): Record<EventsCheckboxFilter.type, FilterCheckboxGroupModel> {
    return {
      type: new FilterCheckboxGroupModel<string, EventsTypeCheckboxFilter, EventsTypeCheckboxFilterValue>('Type', [
        {
          filterName: EventsTypeCheckboxFilter.incident,
          label: EventsTypeCheckboxFilterValue.incident,
          value: dto
            .filter(item => item.name === INCIDENT_MARK)
            .map(({ id }) => id)[0]
            ?.toString(),
        },
        {
          filterName: EventsTypeCheckboxFilter.loss,
          label: EventsTypeCheckboxFilterValue.loss,
          value: dto
            .filter(item => item.name === ACTUAL_LOSS_MARK)
            .map(({ id }) => id)[0]
            ?.toString(),
        },
      ]),
    };
  }
}
