import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Status } from '@core/right-sidenav/right-sidenav.component';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { GraphUser, GraphUserInfo, GraphUserPhoto, GraphUserPresence } from '@shared/models';
import { groupBy, isEmpty, isNil, isNotNil, pick } from '@shared/utils';

import { environment } from '../../../environments/environment';

interface GraphApiResponse<T> {
  responses: T[];
}

interface GraphApiBatchRequest {
  id: string;
  method: 'GET' | 'POST' | 'PUT';
  url: `/users/${string}` | `users${string}`;
  headers?: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ConsistencyLevel?: 'eventual';
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type'?: string;
  };
  responseType?: string;
}

interface GraphResponse<T> {
  id: string;
  status: number;
  body: T;
}

type GraphUserInfoResponse = GraphResponse<{ value: GraphUserInfo[] }>;

type GraphUserPhotoResponse = GraphResponse<Blob>;

type GraphUserPresenceResponse = GraphResponse<{
  id: string;
  availability: Status;
  activity: string;
}>;

@Injectable()
export class GraphService {
  private readonly graphUrl = environment.graphUrl;
  private readonly graphBatchUrl = this.graphUrl + '$batch';
  private readonly statusOk = 200;

  constructor(
    private readonly http: HttpClient,
    private readonly sanitizer: DomSanitizer
  ) {}

  getLoggedUserPhoto(): Observable<SafeUrl> {
    let httpHeaders = new HttpHeaders().set('content-type', 'image/Jpeg');
    return this.http
      .get(this.graphUrl + 'me/photo/$value', { headers: httpHeaders, responseType: 'blob' })
      .pipe(map(e => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))));
  }

  getGraphUsers(emails: string[]): Observable<GraphUser[]> {
    const userInfoBatchRequests = emails.map(user => this.createUserInfoBatchRequest(user));
    return this.getUsersInfo(userInfoBatchRequests).pipe(
      switchMap(graphUsers => {
        const userPhotoBatchRequests = graphUsers.map(user => this.createUserPhotoBatchRequest(user));
        const presenceBatchRequests = graphUsers.map(user => this.createPresenceBatchRequest(user));
        return forkJoin([of(graphUsers), this.getUsersPhotos(userPhotoBatchRequests), this.getUsersPresence(presenceBatchRequests)]);
      }),
      map(([graphUsersInfos, usersPhotos, usersPresence]) => {
        const graphUsers = groupBy([...graphUsersInfos, ...usersPhotos, ...usersPresence], user => user.requestId);
        const resultArray: GraphUser[] = [];

        for (const [key, value] of Object.entries(graphUsers)) {
          const user: Partial<GraphUser> = {
            requestId: key,
          };

          for (const obj of value as GraphUser[]) {
            if (obj.id) {
              user.id = obj.id;
              user.displayName = obj.displayName;
              user.jobTitle = obj.jobTitle;
              user.mail = obj.mail;
              user.userPrincipalName = obj.userPrincipalName;
            }

            if (obj.picture) {
              user.picture = obj.picture;
            }

            if (obj.presence) {
              user.presence = obj.presence;
            }
          }

          resultArray.push(user as GraphUser);
        }
        return resultArray.filter(user => isNotNil(user.picture));
      })
    );
  }

  private getUsersPresence(presenceBatchRequests: GraphApiBatchRequest[]): Observable<GraphUserPresence[]> {
    if (isEmpty(presenceBatchRequests)) {
      return of([]);
    }
    return this.http.post<GraphApiResponse<GraphUserPresenceResponse>>(this.graphBatchUrl, { requests: presenceBatchRequests }).pipe(
      map(response => response.responses),
      map((responses: GraphUserPresenceResponse[]) => this.filterOkResponses(responses)),
      map((responses: GraphUserPresenceResponse[]) =>
        responses.map(response => {
          const userPresence = response.body;
          if (isNil(userPresence)) {
            return undefined;
          }
          return {
            requestId: response.id,
            presence: userPresence.availability,
          } as GraphUserPresence;
        })
      )
    );
  }

  private getUsersPhotos(userPhotoBatchRequests: GraphApiBatchRequest[]): Observable<GraphUserPhoto[]> {
    if (isEmpty(userPhotoBatchRequests)) {
      return of([]);
    }
    return this.http.post<GraphApiResponse<GraphUserPhotoResponse>>(this.graphBatchUrl, { requests: userPhotoBatchRequests }).pipe(
      map(response => response.responses),
      map((responses: GraphUserPhotoResponse[]) => this.filterOkResponses(responses)),
      map((responses: GraphUserPhotoResponse[]) =>
        responses.map(response => {
          const userPhoto = 'data:image/jpeg;base64,' + response.body;
          if (isNil(userPhoto)) {
            return undefined;
          }

          return {
            requestId: response.id,
            picture: userPhoto,
          } as GraphUserPhoto;
        })
      ),
      map(usersResponses => usersResponses.filter(isNotNil))
    );
  }

  private getUsersInfo(userInfoBatchRequests: GraphApiBatchRequest[]): Observable<GraphUserInfo[]> {
    if (isEmpty(userInfoBatchRequests)) {
      return of([]);
    }
    return this.http.post<GraphApiResponse<GraphUserInfoResponse>>(this.graphBatchUrl, { requests: userInfoBatchRequests }).pipe(
      map(response => response.responses),
      map((responses: GraphUserInfoResponse[]) => this.filterOkResponses(responses)),
      map(responses =>
        responses.map(response => {
          const user = response.body.value[0];
          if (isNil(user)) {
            return undefined;
          }

          return {
            ...pick(user, 'id', 'displayName', 'givenName', 'jobTitle', 'mail', 'userPrincipalName'),
            requestId: response.id,
          } as GraphUserInfo;
        })
      ),
      map(usersResponses => usersResponses.filter(isNotNil))
    );
  }

  private getUserSearchQuery(email: string): string {
    return `"mail:${email}"`;
  }

  private createUserInfoBatchRequest(email: string): GraphApiBatchRequest {
    const searchQuery = this.getUserSearchQuery(email);
    return {
      id: email,
      method: 'GET',
      url: `users?$search=${searchQuery}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ConsistencyLevel: 'eventual',
      },
    };
  }

  private createUserPhotoBatchRequest(user: GraphUserInfo): GraphApiBatchRequest {
    return {
      id: user.mail,
      method: 'GET',
      url: `users/${user.id}/photo/$value`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'image/jpeg',
      },
      responseType: 'blob',
    };
  }

  private createPresenceBatchRequest(user: GraphUserInfo): GraphApiBatchRequest {
    return {
      id: user.mail,
      method: 'GET',
      url: `users/${user.id}/presence`,
    };
  }

  private filterOkResponses<T extends GraphResponse<unknown>>(responses: T[]): T[] {
    return responses.filter(response => response.status === this.statusOk);
  }
}
