import { Injectable, Type } from '@angular/core';
import { DateCellRendererComponent } from '@styles/p-table/cell-renderers/date-cell-renderer.component';
import { OverdueCellRendererComponent } from '@styles/p-table/cell-renderers/overdue-cell-renderer/overdue-cell-renderer.component';
import { CellRenderer } from '@styles/p-table/model/cell-renderer.model';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as XLSX from 'xlsx';

import { formatDateToOneOrmDate } from '@shared/pipe';
import { isEnum, isTrue, isUser } from '@shared/utils';

import { UserSettingsColumnsDef } from '../../home/constants/colum-definitions';

@Injectable({
  providedIn: 'root',
})
export class ExcelService<T = object> {
  private readonly excelExtension = '.xlsx';

  exportToExcel(data: T[], columns: UserSettingsColumnsDef<T>, fileName: string): void {
    const extractedData = this.getDataFromVisibleColumns(data, columns);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(extractedData);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}_export${this.excelExtension}`);
  }

  private getDataFromVisibleColumns(data: T[], columns: UserSettingsColumnsDef<T>): T[] {
    const fields = columns.map(column => ({
      field: column.field,
      header: column.header,
      dateColumn: this.isDateColumn(column.cellRenderer),
    }));

    return this.flatUserAndEnumItems(
      data.map(item => {
        const extractedItem: Record<string, unknown> = {};
        fields.forEach(({ field, dateColumn, header }) => {
          if (item.hasOwnProperty(field)) {
            extractedItem[header] = this.getDataFromItem(item, field, dateColumn) as T[Extract<keyof T, string>];
          }
        });
        return extractedItem as T;
      })
    );
  }

  private getDataFromItem(item: T, field: keyof T, dateColumn: boolean): T[keyof T] | string {
    const value: T[keyof T] = item[field];
    if (isTrue(dateColumn)) {
      return formatDateToOneOrmDate(value);
    }

    return value;
  }

  private isDateColumn(columnCellRenderer: Type<CellRenderer<T>>): boolean {
    return columnCellRenderer === DateCellRendererComponent || columnCellRenderer === OverdueCellRendererComponent;
  }

  private flatUserAndEnumItems(rows: T[]): T[] {
    return rows.map(row => this.flattenObject(row));
  }

  private flattenObject(obj: T): T {
    let result: Record<string, unknown> = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (isUser(value)) {
        result[key] = value.user_name;
        return;
      }

      if (isEnum(value)) {
        result[key] = value.name;
        return;
      }
      result[key] = value;
    });

    return result as T;
  }
}
