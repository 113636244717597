import { Observable } from 'rxjs';
import { TestScheduler } from 'rxjs/internal/testing/TestScheduler';

function createTestScheduler(): TestScheduler {
  //@ts-ignore
  return new TestScheduler((actual, expected) => expect(actual).toEqual(expected));
}

export function runMarbleTest<T = unknown, H = unknown>(
  testObservable: Observable<T>,
  ...hotConfigs: {
    fn?: (marble?: H) => void;
    marbles: string;
    values?: { [marble: string]: H };
  }[]
): {
  andExpectToEmit: (
    expectedMarbles: string,
    expectedValues?: { [k: string]: T },
    expectedError?: unknown,
    assertionCallback?: () => void
  ) => void;
} {
  return {
    andExpectToEmit: (
      expectedMarbles: string,
      expectedValues?: { [k: string]: T },
      expectedError?: unknown,
      assertionCallback?: () => void
    ) => {
      createTestScheduler().run(({ expectObservable, hot, flush }) => {
        hotConfigs?.forEach(config => hot(config.marbles, config.values).subscribe(value => config.fn?.(value)));
        expectObservable(testObservable).toBe(expectedMarbles, expectedValues, expectedError);
        flush();
        assertionCallback?.();
      });
    },
  };
}
