import { Component, inject, Signal } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DrawerService } from '@core/drawer/drawer.service';

import { UserStore } from '@core/authorization';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent {
  environmentType = environment.envType;
  call = true;
  drawerOpened$ = this.drawerService.opened$;
  readonly userPhoto: Signal<SafeUrl> = inject(UserStore).userPhoto;

  constructor(
    private readonly router: Router,
    private readonly drawerService: DrawerService
  ) {}

  onClick(): void {
    this.router.navigate(['/']);
  }

  openDrawer(): void {
    this.drawerService.setOpen(true);
  }

  closeDrawer(): void {
    this.drawerService.setOpen(false);
  }
}
