import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-full-text-form-field',
  template: `
    <mat-form-field color="primary" subscriptSizing="dynamic">
      <input #input="ngModel" [ngModel]="value" [placeholder]="placeholder" data-test="full-text-input" matInput />
    </mat-form-field>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormField, MatInput, FormsModule],
})
export class FullTextFormFieldComponent implements AfterViewInit, OnDestroy {
  @Input() placeholder: string;
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('input') input: NgModel;

  private readonly destroy$ = new Subject<void>();

  ngAfterViewInit(): void {
    this.input.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(query => this.valueChange.emit(query));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
