import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import {
  MatNestedTreeNode,
  MatTree,
  MatTreeNestedDataSource,
  MatTreeNode,
  MatTreeNodeDef,
  MatTreeNodeOutlet,
  MatTreeNodeToggle,
} from '@angular/material/tree';

import { TreeNode } from '@shared/components';
import { IsFalsePipe, IsTruePipe } from '@shared/pipe';
import { isEmpty, isEqual, isNil, isNotEmpty, isTrue } from '@shared/utils';

import { SelectionTreeDeferLoadingSkeletonComponent } from './selection-tree-defer-loading-skeleton.component';

@Component({
  selector: 'app-selection-tree',
  template: `
    @defer (on viewport) {
      <mat-tree class="selection-tree" [dataSource]="dataSource" [trackBy]="trackByNodeId" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <div class="mat-tree-node" [class.no-child]="!hasChild(0, node)">
            @if (readonlyNodes | isTrue) {
              <button (click)="deleteButtonClick.emit(node)" mat-icon-button><mat-icon>close</mat-icon></button>
              {{ node.name }}
            } @else {
              <mat-checkbox
                [checked]="checklistSelection.isSelected(node)"
                [id]="node.name + '-checkbox'"
                (click)="$event.stopPropagation()"
                (change)="onSelectionToggleChange(node)"
              >
                {{ node.name }}
              </mat-checkbox>
            }
          </div>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <div class="mat-tree-node">
            <button [attr.aria-label]="'toggle ' + node.name" mat-icon-button matTreeNodeToggle>
              <mat-icon>
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
            @if (readonlyNodes | isTrue) {
              <button (click)="deleteButtonClick.emit(node)" mat-icon-button><mat-icon>close</mat-icon></button>
              {{ node.name }}
            } @else {
              <mat-checkbox
                [checked]="descendantsAllSelected(node)"
                [id]="node.name + '-checkbox'"
                [indeterminate]="descendantsPartiallySelected(node)"
                (click)="$event.stopPropagation()"
                (change)="onSelectionToggleChange(node)"
              >
                {{ node.name }}
              </mat-checkbox>
            }
          </div>
          @if (treeControl.isExpanded(node)) {
            <div role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          }
        </mat-nested-tree-node>
      </mat-tree>
    } @placeholder {
      <app-selection-tree-defer-loading-skeleton></app-selection-tree-defer-loading-skeleton>
    }
  `,
  styles: [
    `
      mat-tree.selection-tree {
        $indent: 24px;

        > mat-tree-node > .mat-tree-node.no-child {
          padding-left: $indent;
        }

        .mat-nested-tree-node div[role='group'],
        div[role='group'] > .mat-tree-node {
          padding-left: $indent;
        }
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTree,
    MatTreeNode,
    MatTreeNodeDef,
    MatTreeNodeToggle,
    MatCheckbox,
    MatNestedTreeNode,
    MatIcon,
    MatTreeNodeOutlet,
    MatIconButton,
    IsFalsePipe,
    SelectionTreeDeferLoadingSkeletonComponent,
    IsTruePipe,
  ],
})
export class SelectionTreeComponent<T> implements AfterViewInit {
  @Input({
    transform: booleanAttribute,
  })
  expandAllOnInit = true;
  @Input({
    transform: booleanAttribute,
  })
  readonlyNodes = false;

  @Output()
  selectionChanged = new EventEmitter<TreeNode<T>[]>();
  @Output()
  deleteButtonClick = new EventEmitter<TreeNode<T>>();

  readonly treeControl: NestedTreeControl<TreeNode<T>> = new NestedTreeControl<TreeNode<T>>(node => node.children);
  readonly dataSource = new MatTreeNestedDataSource<TreeNode<T>>();
  readonly checklistSelection = new SelectionModel<TreeNode<T>>(true, [], true, (a, b) => this.compareFn(a, b));

  private readonly cdr = inject(ChangeDetectorRef);
  private deselectAllNodes: TreeNode<T>[] = [];

  @Input({
    required: true,
  })
  set nodes(treeNodes: TreeNode<T>[]) {
    if (this.readonlyNodes) {
      this.dataSource.data = null;
    }
    this.treeControl.dataNodes = treeNodes;
    this.dataSource.data = treeNodes;
    this.deselectAllNodes = this.findDeselectAllNodes(treeNodes);
    this.expandAllNodes();
    this.cdr.detectChanges();
  }

  @Input()
  set preSelectedNodes(nodes: TreeNode<T>[]) {
    if (isNotEmpty(nodes)) {
      this.preSelectNodes(nodes);
    }
  }

  ngAfterViewInit(): void {
    this.expandAllNodes();
  }

  hasChild = (_: number, node: TreeNode<T>): boolean => !!node.children && node.children.length > 0;

  trackByNodeId(index: number, node: TreeNode<T>): number {
    return node.id;
  }

  descendantsPartiallySelected(node: TreeNode<T>): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  descendantsAllSelected(node: TreeNode<T>): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  deselectAll(): void {
    this.checklistSelection.clear();
    this.selectionChanged.emit(this.checklistSelection.selected);
  }

  selectNodes(nodeIds: TreeNode<T>[]): void {
    this.checklistSelection.select(...nodeIds);
    this.selectionChanged.emit(this.checklistSelection.selected);
  }

  unSelectNodes(nodeIds: TreeNode<T>[]): void {
    this.checklistSelection.deselect(...nodeIds);
    this.selectionChanged.emit(this.checklistSelection.selected);
  }

  onSelectionToggleChange(node: TreeNode<T>): void {
    if (isTrue(node.deselectAllNode)) {
      return this.selectUncheckAllNode(node);
    }

    this.checklistSelection.deselect(...this.deselectAllNodes);
    this.checklistSelection.toggle(node);

    this.selectOrUnselectDescendants(node);
    this.checkAllParentsSelection(node);
    this.selectionChanged.emit(this.getSelectedNodesWithParents());
  }

  private descendantsAllDeselected(node: TreeNode<T>): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => !this.checklistSelection.isSelected(child));
  }

  private preSelectNodes(nodes: TreeNode<T>[]): void {
    this.checklistSelection.clear();
    const preSelectedIds = new Set<number>();

    nodes.forEach(node => this.collectIds(node, preSelectedIds));

    this.selectNodesRecursively(nodes, preSelectedIds);
  }

  private collectIds(node: TreeNode<T>, preSelectedIds: Set<number>): void {
    preSelectedIds.add(node.id);
    if (node.children) {
      node.children.forEach(child => this.collectIds(child, preSelectedIds));
    }
  }

  private selectNodesRecursively(subNodes: TreeNode<T>[], preSelectedIds: Set<number>): void {
    subNodes.forEach(node => {
      if (preSelectedIds.has(node.id)) {
        this.checklistSelection.select(node);
      }
      if (node.children) {
        this.selectNodesRecursively(node.children, preSelectedIds);
      }
    });
  }

  private compareFn(node1: TreeNode<T>, node2: TreeNode<T>): boolean {
    if (isNil(node1.id) && isNil(node2.id)) {
      return isEqual(node1, node2);
    }

    return node1.id === node2.id;
  }

  private selectOrUnselectDescendants(node: TreeNode<T>): void {
    if (this.readonlyNodes) {
      return;
    }
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

  private selectUncheckAllNode(node: TreeNode<T>): void {
    this.checklistSelection.clear();
    this.checklistSelection.toggle(node);
    this.selectionChanged.emit(this.checklistSelection.selected);
  }

  private checkAllParentsSelection(node: TreeNode<T>): void {
    if (this.readonlyNodes) {
      return;
    }
    let parent = this.getParentNode(node);
    while (parent) {
      if (!this.descendantsAllSelected(parent)) {
        this.checklistSelection.deselect(parent);
      } else {
        this.checklistSelection.select(parent);
      }
      parent = this.getParentNode(parent);
    }
  }

  private getParentNode(node: TreeNode<T>): TreeNode<T> | undefined {
    let parent: TreeNode<T>;
    this.dataSource.data.forEach(dataNode => {
      if (isEmpty(dataNode.children)) {
        return;
      }
      if (dataNode.children.some(child => child.id === node.id)) {
        parent = dataNode;
        return;
      }
      dataNode.children.forEach(childNode => {
        if (this.treeControl.getDescendants(childNode).some(child => child === node)) {
          parent = childNode;
        }
      });
    });
    return parent;
  }

  private findDeselectAllNodes(nodes: TreeNode<T>[]): TreeNode<T>[] {
    const deselectAllNodes: TreeNode<T>[] = [];
    nodes.forEach(node => {
      if (isTrue(node.deselectAllNode)) {
        deselectAllNodes.push(node);
      }
      if (node.children) {
        deselectAllNodes.push(...this.findDeselectAllNodes(node.children));
      }
    });
    return deselectAllNodes;
  }

  private expandAllNodes(): void {
    if (this.expandAllOnInit) {
      this.treeControl.expandAll();
      this.cdr.detectChanges();
    }
  }

  private getSelectedNodesWithParents(): TreeNode<T>[] {
    const selectedNodes = [...this.checklistSelection.selected];
    selectedNodes.forEach(node => {
      let parent = this.getParentNode(node);
      while (parent) {
        if (!this.descendantsAllSelected(parent) && !selectedNodes.includes(parent) && !this.descendantsAllDeselected(parent)) {
          selectedNodes.push(parent);
        }
        parent = this.getParentNode(parent);
      }
    });
    return selectedNodes;
  }
}
