import { Pipe, PipeTransform } from '@angular/core';

import { isNotEmpty, isNotNil, isValidDate } from '@shared/utils';

@Pipe({
  standalone: true,
  name: 'isValidDate',
})
export class IsValidDatePipe<T> implements PipeTransform {
  transform(value: string | number | Date, availableDateColumns?: (keyof T)[], columnsField?: keyof T): boolean {
    if (isNotEmpty(availableDateColumns) && isNotNil(columnsField)) {
      return isValidDate(value) && availableDateColumns.includes(columnsField);
    }
    return isValidDate(value);
  }
}
