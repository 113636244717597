import { RiskCaseEnumItem } from 'oneorm-api-http-client/model/riskCaseEnumItem';

import { isNil, isObject } from '@shared/utils';

export const isEnum = (row: unknown): row is RiskCaseEnumItem => {
  if (isNil(row) || !isObject(row)) {
    return false;
  }

  return row.hasOwnProperty('id') && row.hasOwnProperty('name');
};
