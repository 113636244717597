import { Observable } from 'rxjs';

import { RiskCaseType } from '@shared/models';

import { CaseFilterType } from '../../home/model/case-filter-type.model';
import { TableData } from '../../home/model/table-data.model';

export abstract class HomeService<T, P = unknown> {
  abstract getData(caseType?: RiskCaseType, type?: CaseFilterType, params?: P): Observable<TableData<T>>;
}
